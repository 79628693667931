import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export const RichTextField = ({label, control, style}) => (
 
 <div>
    <h5 id='editorTitle' style={style.textGray}>{label}</h5>
    <CKEditor
      data={String(control.input.value)}  
      editor={ClassicEditor}
      onChange={(event, editor) => {
        const data = editor.getData();
        control.input.onChange(data);
      }}
      onFocus={(event, editor) => {
   document.getElementById('editorTitle').style=style.textGray;
      }}
    />
  </div>

);

export default RichTextField;