import React from 'react';
import { Field, FieldArray } from 'redux-form/immutable';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import clsx from 'clsx';
import Radio from '@material-ui/core/Radio';
import Checkbox from '@material-ui/core/Checkbox';

import RenderTextField from 'components/Form/TextField';
import RenderSelectField from 'components/Form/SelectField';
import RenderSwitchField from 'components/Form/SwitchField';
import RenderReadField from 'components/Form/ReadField';
import FieldTypeOptions from './FieldTypeOptions';
import GenderTypeOptions from './CamposPredeterminados/GenderTypeOptions';
import EstCivilTypeOptions from './CamposPredeterminados/EstCivilTypeOptions';
import GradoTypeOptions from './CamposPredeterminados/GradoTypeOptions';
import SitLabTypeOptions from './CamposPredeterminados/SitLabTypeOptions';

class EditFieldsCard extends React.PureComponent {
  state = {
    typeField: false,
  }

  componentWillMount = () => {
    const { fields, index } = this.props;
    this.setState({ typeField: fields.get(index).getIn(['type', 'value']) });
  }

  componentWillReceiveProps = (nextProps) => {
    const { fields, index } = nextProps;
    this.setState({ typeField: fields.get(index).getIn(['type', 'value']) });
  }

  handleChangeType = (input, newItem) => {
    const { stateApp, fields, indexActive  } = this.props;
    let setItem;
    if (newItem.get('value') === 1) {
      setItem = newItem.set('format', stateApp.getIn(['types', 'shortAnswerValueTypes', 0]));
    } else {
      setItem = newItem;
    }
    input.onChange(setItem);
    
    //modificamos el titulo del campo segun la selección
    this.setState({ typeField: newItem.get('value') });
    fields.get(indexActive)._root.entries[1].pop()
    fields.get(indexActive)._root.entries[1].push(newItem.get('label'))
    // =====================================================================
  }

  render() {
    const {
      classes, member, stateApp, indexActive, index, fields,
    } = this.props;
    const readOnly = indexActive !== index;
    const { typeField } = this.state;
    let content = null;

    switch (typeField) {
      case 1:
        content = (
          <>
            <Grid item xs={2}>
              {readOnly ? (
                <Field
                  className={classes.valueTypeText}
                  name={`${member}.type.format`}
                  renderValue={input => (input.value && input.value.get('label')) || '-'}
                  component={RenderReadField}
                />
              ) : (
                <Field
                  label="Tipo de valor"
                  name={`${member}.type.format`}
                  margin="normal"
                  fullWidth
                  renderValue={input => input.value.get('label')}
                  className={classes.valueTypeSelect}
                  componentProps={{
                    menuPortalTarget: document.body,
                    menuPosition: 'absolute',
                    menuPlacement: 'auto',
                    isSearchable: false,
                    options: stateApp.getIn(['types', 'shortAnswerValueTypes']),
                    noOptionsMessage: () => 'Vacío',
                  }}
                  component={RenderSelectField}
                />
              )}
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1" gutterBottom className={classes.textRequest}>
                Texto de respuesta breve
              </Typography>
            </Grid>
          </>
        );
        break;
      case 2:
        content = (
          <Grid item xs={10}>
            <Typography variant="subtitle1" gutterBottom className={classes.textRequest}>
              Texto de respuesta larga
            </Typography>
          </Grid>
        );
        break;
      case 3:
        content = (
          <Grid item xs={12}>
            <FieldArray
              typeField={typeField}
              readOnly={readOnly}
              otherButton
              renderIcon={() => (<Radio disabled />)}
              name={`${member}.answer`}
              component={FieldTypeOptions}
              handleShowMessage={this.handleShowMessage}
            />
          </Grid>
        );
        break;
      case 4:
        content = (
          <Grid item xs={12}>
            <FieldArray
              typeField={typeField}
              readOnly={readOnly}
              renderIcon={() => (<Checkbox disabled />)}
              name={`${member}.answer`}
              component={FieldTypeOptions}
              handleShowMessage={this.handleShowMessage}
            />
          </Grid>
        );
        break;
      case 5:
        content = (
          <Grid item xs={12}>
            <FieldArray
              typeField={typeField}
              readOnly={readOnly}
              renderIcon={indexArray => (<Typography>{`${indexArray}.`}</Typography>)}
              name={`${member}.answer`}
              component={FieldTypeOptions}
              handleShowMessage={this.handleShowMessage}
            />
          </Grid>
        );
        break;
      case 6:
        content = (
          <>
            <Grid item xs={2}>
                <Field
                  className={classes.valueTypeText}
                  name={`${member}.type.format`}
                  renderValue={input => (input.value && input.value.get('label')) || '-'}
                  component={RenderReadField}
                />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1" gutterBottom className={classes.textRequest}>
                DD/MM/YYYY
              </Typography>
              </Grid>
            </>
          );
          break;
          case 7:
            content = (
              <Grid item xs={12}>
                <FieldArray
                  typeField={typeField}
                  readOnly={readOnly}
                  otherButton
                  renderIcon={() => (<Radio disabled />)}
                  name={`${member}.answer`}
                  component={GenderTypeOptions}
                  handleShowMessage={this.handleShowMessage}
                />
              </Grid>
            );
            break;
            case 8:
              content = (
                <Grid item xs={12}>
                  <FieldArray
                    typeField={typeField}
                    readOnly={readOnly}
                    otherButton
                    renderIcon={() => (<Radio disabled />)}
                    name={`${member}.answer`}
                    component={EstCivilTypeOptions}
                    handleShowMessage={this.handleShowMessage}
                  />
                </Grid>
              );
              break;
              case 9:
                content = (
                  <>
                    <Grid item xs={2}>
                        <Field
                          className={classes.valueTypeText}
                          name={`${member}.type.format`}
                          renderValue={input => (input.value && input.value.get('label')) || '-'}
                          component={RenderReadField}
                        />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1" gutterBottom className={classes.textRequest}>
                        DEPARTAMENTO, PROVINCIA, DISTRITO
                      </Typography>
                      </Grid>
                    </>
                  );
                break;
              case 10:
                content = (
                  <Grid item xs={12}>
                    <FieldArray
                      typeField={typeField}
                      readOnly={readOnly}
                      otherButton
                      renderIcon={() => (<Radio disabled />)}
                      name={`${member}.answer`}
                      component={GradoTypeOptions}
                      handleShowMessage={this.handleShowMessage}
                    />
                  </Grid>
                );
                break;
                case 11:
                  content = (
                    <Grid item xs={12}>
                      <FieldArray
                        typeField={typeField}
                        readOnly={readOnly}
                        otherButton
                        renderIcon={() => (<Radio disabled />)}
                        name={`${member}.answer`}
                        component={SitLabTypeOptions}
                        handleShowMessage={this.handleShowMessage}
                      />
                    </Grid>
                  );
                  break;
                  case 12:
                    content = (
                      <>
                        <Grid item xs={2}>
                            <Field
                              className={classes.valueTypeText}
                              name={`${member}.type.format`}
                              renderValue={input => (input.value && input.value.get('label')) || '-'}
                              component={RenderReadField}
                            />
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="subtitle1" gutterBottom className={classes.textRequest}>
                            Número de Hijos
                          </Typography>
                        </Grid>
                      </>
                    );
                    break;
      default:
        content = null;
    }

    return (
      <>
        <CardContent className={clsx(classes.cardContent, 'bodyFormItem')}>
          <Grid container spacing={3} className={clsx({ [classes.cardContentHead]: readOnly })}>
            <Grid item xs={readOnly ? 12 : 8}>
              <Field
                placeholder="Nombre de Campo"
                name={`${member}.question`}
                margin="normal"
                className={clsx('input-question', classes.firstInput, { [classes.firstInputRead]: readOnly })}
                fullWidth
                component={RenderTextField}
              />
            </Grid>
            {!readOnly && (
              <Grid item xs={4} className={classes.selectType}>
                <div>
                  <Field
                    label="Tipo"
                    name={`${member}.type`}
                    margin="normal"
                    fullWidth
                    renderValue={input => input.value.get('label')}
                    componentProps={{
                      menuPortalTarget: document.body,
                      menuPosition: 'absolute',
                      menuPlacement: 'auto',
                      isSearchable: false,
                      options: stateApp.getIn(['types', 'fields']),
                      noOptionsMessage: () => 'Vacío',
                      onChangeDispatch: this.handleChangeType,

                    }}
                    component={RenderSelectField}
                  />
                </div>
              </Grid>
            )}
            {content}
          </Grid>
        </CardContent>
        {!readOnly && (
          <CardActions className={classes.cardActions}>
            <div style={{ flex: 'auto' }} />
            <IconButton onClick={() => fields.remove(index)}>
              <DeleteIcon />
            </IconButton>
            <div className={classes.requiredSwitchWrap}>
              <Field
                label="Obligatorio"
                name={`${member}.required`}
                component={RenderSwitchField}
              />
            </div>
          </CardActions>
        )}
      </>
    );
  }
}


export default EditFieldsCard;
