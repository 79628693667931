import React from 'react';
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon';

export default createSvgIcon(
  <React.Fragment>
    <g>
      <circle cx="6.5" cy="9.5" r="1.5" />
      <circle cx="6.5" cy="14.5" r="1.5" />
      <circle cx="12.5" cy="9.5" r="1.5" />
      <circle cx="12.5" cy="14.5" r="1.5" />
      <circle cx="18.5" cy="9.5" r="1.5" />
      <circle cx="18.5" cy="14.5" r="1.5" />
    </g>
  </React.Fragment>
  , 'AccessTime'); // eslint-disable-line