import React from 'react';
import PropTypes from 'prop-types';
import Loading from 'components/Loading';
import Notice from 'components/Notice';
import config from 'config.json';
import { SUCCESS, FAILURE, REQUEST } from 'helpers/constants';

class BaseContainer extends React.PureComponent {
  static defaultProps = {
    stateMain: false,
    stateSecond: false,
  }

  static propTypes = {
    stateMain: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.objectOf(PropTypes.any),
    ]),
    stateSecond: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.objectOf(PropTypes.any),
    ]),
  };

  toRenderLoading = hasContainer => (hasContainer ? (
    <div
      className="wh"
      style={{
        position: 'absolute',
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        zIndex: 1,
      }}
    >
      <Loading />
    </div>
  ) : (
    <Loading />
  ));

  toRenderTitlePage = () => '';

  renderTitlePage = () => {
    const title = this.toRenderTitlePage();
    document.title = `${title}${title ? ' // ' : ''}${config.titlePage}`;
  };

  toSuccessState = () => false;

  render() {
    const { stateMain, stateSecond } = this.props;
    const status = this.ignoreStatus ? SUCCESS : ((stateMain && stateMain.get('status')) || false);
    const statusSecond = (stateSecond && stateSecond.get('status')) || false;
    this.renderTitlePage();
    if (status === FAILURE) {
      return (
        <Notice
          type="info"
          detail={{
            title: '⚡',
            body: stateMain.get('data'),
          }}
        />
      );
    } else if (status === SUCCESS) { // eslint-disable-line
      return (
        <>
          {statusSecond === REQUEST && this.toRenderLoading(true)}
          {this.toRender()}
        </>
      );
    }

    return this.toRenderLoading();
  }
}

export default BaseContainer;
