import React from 'react';
import { Field } from 'redux-form/immutable';
import Grid from '@material-ui/core/Grid';
import { fromJS } from 'immutable';

import withStyles from '@material-ui/core/styles/withStyles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import RenderTextField from 'components/Form/TextField';
import { slugify, UCFirst } from 'helpers/utils';
import { OTROS } from 'helpers/constants'

const styles = theme => ({
  renderTextField: props => ({
    flex: 'auto',
    width: 'auto',
    marginTop: theme.spacing(1),
    lineHeight: `${theme.spacing(4)}px`,
    '& > div:before': {
      borderBottom: props.readOnly ? 'none' : '1px dotted rgba(0, 0, 0, 0.2)',
    },
    '& input': {
      padding: '4px 0 5px',
    },
  }),
  lblAdd: {
    '&:hover': {
      borderBottom: '1px dotted rgba(0, 0, 0, 0.8)',
    },
  },
  lblOtros: {
    color: theme.palette.primary[500],
    cursor: 'pointer',
    '&:hover': {
      borderBottom: `1px solid ${theme.palette.primary[500]}`,
    },
  },
  icon: {
    '& > p': {
      // lineHeight: `${theme.spacing(6)}px`,
      padding: theme.spacing(1.5),
    },
  },
});

class EstCivilTypeOptions extends React.PureComponent {
  lastRef = React.createRef();

  componentDidMount = () =>{
    const { fields } = this.props
    const fieldsize = fields.length
    if (fieldsize > 0) {
      fields.removeAll()
    }
    fields.push(fromJS({label:"Casado(a)",value: "CA"}));
    fields.push(fromJS({label:"Divorciado(a)",value: "DI"}));
    fields.push(fromJS({label:"Soltero(a)",value: "SO"}));
    fields.push(fromJS({label:"Conviviente",value: "CO"}));
    fields.push(fromJS({label:"Separado(a)",value: "SE"}));
    fields.push(fromJS({label:"Viudo(a)",value: "VI"}));
  }
  handleAdd = (event) => {
    const { fields } = this.props; // eslint-disable-line
    const getAll = fields.getAll();
    const existOther = getAll.find(obj => obj.get('label') === OTROS);
    const id = fields.length;
    const newItem = fromJS({
      label: event.target.innerText.indexOf('"OTROS"') >= 0 ? OTROS : `Opción ${id + 1}`,
      value: (new Date()).getTime(),
    });
    if (existOther) {
      fields.insert(id - 1, newItem);
    } else {
      fields.push(newItem);
    }
    setTimeout(() => {
      const selected = this.lastRef.current.parentNode.querySelectorAll('[type=text]');
      if (selected.length > 0) {
        selected[selected.length - 1].focus();
        selected[selected.length - 1].select();
      }
    }, 100);
  }

  render() {
    const { fields, classes, renderIcon, otherButton, readOnly, typeField } = this.props; // eslint-disable-line  
    const getAll = fields.getAll();
    if (!getAll) return null;

    const existOther = getAll.find(obj => obj.get('label') === OTROS);

    return (
      <>
        {fields.map((member, index) => {
          const item = fields.get(index);
          return (
            <Grid
              key={slugify(`${member}.label`)}
              container
              justify="space-between"
            >
              <div className={classes.icon}>
                {renderIcon(index + 1)}
              </div>
              {item.get('label') === OTROS ? (
                <div className={classes.renderTextField}>
                  <Typography
                    component="span"
                  >
                    {UCFirst(OTROS)}
                  </Typography>
                </div>
              ) : (
                <Field
                  fullWidth
                  margin="normal"
                  name={`${member}.label`}
                  className={classes.renderTextField}
                  component={RenderTextField}
                />
              )}
              {!readOnly && (
                <IconButton onClick={() => fields.remove(index)}>
                  <CloseIcon />
                </IconButton>
              )}
            </Grid>
          );
        })}
        {!readOnly && (
          <Grid
            key="add-new-type-opt3"
            container
            justify="space-between"
            ref={this.lastRef}
          >
            <div className={classes.icon}>
              {renderIcon(fields.length + 1)}
            </div>
            <div className={classes.renderTextField}>
              <Typography
                component="span"
                className={classes.lblAdd}
                onClick={this.handleAdd}
              >
                Agregar una opción
              </Typography>
              {otherButton && !existOther && ' o '}
              {otherButton && !existOther && (
                <Typography
                  component="a"
                  variant="button"
                  className={classes.lblOtros}
                  onClick={this.handleAdd}
                >
                  Agregar &quot;OTROS&quot;
                </Typography>
              )}
            </div>
          </Grid>
        )}
      </>
    );
  }
}

export default withStyles(styles)(EstCivilTypeOptions);
