import { fromJS, Map } from 'immutable';
import { createAction, handleActions } from 'redux-actions';
import { put, takeLatest, call } from 'redux-saga/effects';
import { push } from 'connected-react-router/immutable';
import { REQUEST, FAILURE, SUCCESS } from 'helpers/constants';

import request from 'helpers/request';
import localStore from 'helpers/localStore';
import { types as typesNotification } from 'reducers/notification';

// Constants
export const types = {
  LOGIN_REQUEST: 'USER/LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USER/LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USER/LOGIN_FAILURE',
  
  LIST_REQUEST: 'USER/LIST_REQUEST',
  LIST_SUCCESS: 'USER/LIST_SUCCESS',
  LIST_FAILURE: 'USER/LIST_FAILURE',

  GET_REQUEST: 'USER/GET_REQUEST',
  GET_SUCCESS: 'USER/GET_SUCCESS',
  GET_FAILURE: 'USER/GET_FAILURE',
  GET_INITIAL: 'USER/GET_INITIAL',
  
  SAVE_REQUEST: 'USER/SAVE_REQUEST',
  SAVE_SUCCESS: 'USER/SAVE_SUCCESS',
  SAVE_FAILURE: 'USER/SAVE_FAILURE',

};

// Actions
export const loginUser = createAction(types.LOGIN_REQUEST);
export const listUser = createAction(types.LIST_REQUEST);
export const getUser = createAction(types.GET_REQUEST);
export const saveUser = createAction(types.SAVE_REQUEST);

const initialState = fromJS({
  login: {
    status: null,
    data: new Map(),
  },
  list: {
    status: null,
    data: new Map(),
  },
  get: {
    status: null,
    data: new Map(),
  },
  save: {
    status: null,
    data: new Map(),
  },

});

// Reducer
export default handleActions(
  {
    [types.LOGIN_REQUEST]: state => state.setIn(['login', 'status'], REQUEST),
    [types.LOGIN_SUCCESS]: (state, action) => state
      .setIn(['login', 'status'], SUCCESS)
      .setIn(['login', 'data'], fromJS(action.data)),
    [types.LOGIN_FAILURE]: (state, action) => state
      .setIn(['login', 'status'], FAILURE)
      .setIn(['login', 'data'], action.data),

    [types.LIST_REQUEST]: state => state.setIn(['list', 'status'], REQUEST),
    [types.LIST_SUCCESS]: (state, action) => state
      .setIn(['list', 'status'], SUCCESS)
      .setIn(['list', 'data'], fromJS(action.data)),
    [types.LIST_FAILURE]: (state, action) => state
      .setIn(['list', 'status'], FAILURE)
      .setIn(['list', 'data'], action.data),

    [types.GET_REQUEST]: state => state.setIn(['get', 'status'], REQUEST),
    [types.GET_SUCCESS]: (state, action) => state
      .setIn(['get', 'status'], SUCCESS)
      .setIn(['get', 'data'], fromJS(action.data)),
    [types.GET_FAILURE]: (state, action) => state
      .setIn(['get', 'status'], FAILURE)
      .setIn(['get', 'data'], action.data),

    [types.SAVE_REQUEST]: state => state.setIn(['save', 'status'], REQUEST),
    [types.SAVE_SUCCESS]: (state, action) => state
      .setIn(['save', 'status'], SUCCESS)
      .setIn(['save', 'data'], fromJS(action.data)),
    [types.SAVE_FAILURE]: (state, action) => state
      .setIn(['save', 'status'], FAILURE)
      .setIn(['save', 'data'], action.data),
  },
  initialState,
);

// sagas
function* loginUserSaga({ payload }) {
  const response = yield call(request, {
    api: 'login',
    method: 'POST',
    authorization: payload,
  });
  if (response.success) {
    localStore.set('token', response.body.token);
    yield put({ type: types.LOGIN_SUCCESS, data: response.body.user });
    yield put(push('/forms'));
  } else {
    yield put({
      type: typesNotification.ADD,
      notification: {
        message: response.body,
        type: 'error',
      },
    });
    yield put({ type: types.LOGIN_FAILURE, data: response.body });
  }
}

function* listUserSaga({ payload }) {
  const response = yield call(request, {
    api: 'user/list',
    method: 'POST',
    body: payload,
  });
  if (response.success) {
    yield put({ type: types.LIST_SUCCESS, data: response.body });
  } else {
    yield put({
      type: typesNotification.ADD,
      notification: {
        message: response.body,
        type: 'error',
      },
    });
    yield put({ type: types.LIST_FAILURE, data: response.body });
  }
}

function* getUserSaga({ payload }) {
  let response;
  if (payload && payload.initialize) {
    yield put({ type: types.GET_INITIAL });
    return;
  }
  if (payload !== 'new') {
    response = yield call(request, {
      api: `user/get/${payload}`,
      method: 'POST',
    });
  } else {
    response = { success: true, body: { isActive: false } };
  }
  if (response.success) {
    yield put({ type: types.GET_SUCCESS, data: response.body });
  } else {
    yield put({
      type: typesNotification.ADD,
      notification: {
        message: response.body,
        type: 'error',
      },
    });
    yield put({ type: types.GET_FAILURE, data: response.body });
  }
}

function* saveUserSaga({ payload }) {
  const response = yield call(request, {
    api: 'user/save',
    method: 'POST',
    body: payload,
  });
  if (response.success) {
    yield put({ type: types.SAVE_SUCCESS, data: response.body });
    yield put({
      type: typesNotification.ADD,
      notification: {
        message: 'Listo!, datos guardados',
        type: 'success',
      },
    });
  } else {
    yield put({
      type: typesNotification.ADD,
      notification: {
        message: response.body,
        type: 'error',
      },
    });
    yield put({ type: types.SAVE_FAILURE, data: response.body });
  }
}

export const listUserAsync = props => request({
  api: 'user/list',
  method: 'POST',
  body: props,
});

export const userSagas = [
  takeLatest(types.LOGIN_REQUEST, loginUserSaga),
  takeLatest(types.LIST_REQUEST, listUserSaga),
  takeLatest(types.GET_REQUEST, getUserSaga),
  takeLatest(types.SAVE_REQUEST, saveUserSaga),
];
