import React from 'react';
import { formatFromUnix } from 'helpers/utils';
import ListLeads from 'components/ListSearch/leads';
import BaseContainer from 'components/BaseContainer';
import binder from 'helpers/binder';
import { toUpper } from 'lodash';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';
import styles from './leadStyles';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {exportleadsCSVAsync, listLeadsAsync } from 'reducers/leads';


class LandingList extends BaseContainer {
  state = {
    tabSelecIndex: 0,
    anchorEl: null,
    typeFile: 'xls',
    formatTop: false, // false estandar true formato top
    csvLoading: false
  }
  ignoreStatus = true;

  handleDownload = (event) => {
    event.stopPropagation();

    this.setState({ anchorEl: event.currentTarget });
    // const { current } = this.formDown;
    // current.submit();
  }
  handleCloseMenu = (event, type) => {
    event.stopPropagation();
    this.setState({ anchorEl: null });
    if (type === 'csv' || type === 'xls') {
      const { current } = this.formDown;
      this.setState({ typeFile: type });
      current.submit();
    }
  }
  setPickerStatus = (event) => {
    if (event) { event.stopPropagation(); }
    this.setState({ anchorEl: null });
  }

  handleDateChange = (date, prefix) => {
    if (date) {
      date = date.setHours(0, 0, 0, 0);
      this.handleExportCsv(null, prefix, date/1000);
    }
  };

  handleExportCsv = (event, prefix, today) => {
    if (event) { event.stopPropagation(); }
    const {csvLoading} = this.state;
    if (!csvLoading) {
      this.setState({ anchorEl: null, csvLoading: true });
      try {
        exportleadsCSVAsync({
          lprefix: prefix,
          today: today,
        }).then((result) => {
          if (result.success === true) {
            const { statusCode, file } = result.body;
            this.setState({ csvLoading: false })
            if (statusCode === 200) {
              var a = document.createElement("a");
              a.href = file;
              a.setAttribute("download", `leads_forma_${prefix}.csv`);
              a.click();
            }
          }
        });
      } catch (error) {
        this.setState({ csvLoading: false })
        console.log('error', error);
      }
    }
  }



  handleFilter = (values) => {
    const tableList = document.getElementById('table-list');
    tableList.dispatchEvent(new Event('filtered'));
  }
  toRenderTitlePage = () => 'Lista de leads';

  toRender() {
    const { match } = this.props;
    const urlArray = match.url.split('/');
    const lprefix = { 'lprefix': toUpper(urlArray[urlArray.length - 1]) };
    const initial_lprefix = toUpper(urlArray[urlArray.length - 1]);
    const { classes } = this.props; // eslint-disable-line
    const {  anchorEl, csvLoading,tabSelecIndex} = this.state;
    return (
      <React.Fragment>
        <Grid container alignItems="center" wrap="nowrap" justify="space-between" spacing={0} className={classes.hdRoot}>
          <Grid item className={classes.hdRootCol1}>
            <IconButton
              aria-label="Return"
              className={classes.backIcon}
              component={Link}
              to="/landings"
              edge="start"
              title="Volver a lista de Landings"
            >
              <ArrowBackIcon />
            </IconButton>
          </Grid>
          <Grid item className={classes.hdRootCol3}>
          
          <Tabs
          value={tabSelecIndex}
          indicatorColor="primary"
          textColor="primary"
          onChange={this.handleChangeTab}
        >
            <Tab label={(<div className={classes.downXls}>
              <span>Descargar</span>
              <span
                className={`svg ${csvLoading ? 'forma-spinner' : ''}`}
                role="button"
                tabIndex={0}
                onClick={this.handleDownload}
              >
                {!csvLoading && (<CloudDownloadIcon title="Descargar XLS" />)}
              </span>
              <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={this.handleCloseMenu}
            >
              { /*<MenuItem onClick={evt => this.handleCloseMenu(evt, 'xls')}>XLS</MenuItem>*/ }
              <MenuItem disabled={csvLoading} onClick={evt => this.handleExportCsv(evt, initial_lprefix, false)}>CSV (Todos los registros)</MenuItem>
              <MenuItem disabled={csvLoading}>
                <KeyboardDatePicker
                  label="CSV (Participantes del día)"
                  InputProps={{ readOnly: true }}
                  onClick={(evt) => this.setPickerStatus(evt)}
                  onClose={(evt) => this.setPickerStatus(evt)}
                  margin="normal"
                  format="dd/MM/yyyy"
                  views={['date']}
                  disableFuture={true}
                  autoOk
                  // value={selectedDate}
                  onChange={(date) => this.handleDateChange(date, initial_lprefix)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MenuItem>
            </Menu>
            </div>)} />
        </Tabs>
          </Grid>
        </Grid>
        <ListLeads
          title="Leads"
          handleListAsync={listLeadsAsync}
          filtro={lprefix}
          formatDataRow={row => ({
            ...row,
            createdAt: formatFromUnix(row.createdAt, 'dd/MM/yyyy HH:mm:ss'),
          })}
        />
      </React.Fragment>
    );
  }
}

// export default connect()(LeadList);


export default binder({
  styles,
  states: {
    stateUser: ['user', 'login', 'data']
  },
  component: LandingList,
});
