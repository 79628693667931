import React from 'react';
import { formatFromUnix } from 'helpers/utils';
import Listlandings from 'components/ListSearch/landings';
import BaseContainer from 'components/BaseContainer';
import { listLandingsAsync } from 'reducers/leads';
import binder from 'helpers/binder';

class LandingList extends BaseContainer {
  ignoreStatus = true;
  handleEdit = (item) => {
    const { history, match } = this.props;
    history.push(`${match.url}/${item.prefix.toLowerCase()}`);
  }
  toRenderTitlePage = () => 'Lista de leads';

  toRender() {
    return (
      <React.Fragment>
        <Listlandings
          title="Lista de Landings"
          handleListAsync={listLandingsAsync}
          handleEdit={this.handleEdit}
          formatDataRow={row => ({
            ...row,
            createdAt: formatFromUnix(row.createdAt, 'dd/MM/yyyy HH:mm:ss'),
          })}
        />
      </React.Fragment>
    );
  }
}

// export default connect()(LeadList);


export default binder({
  states: {
    stateUser: ['user', 'login', 'data']
  },
  component: LandingList,
});
