import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import withStyles from '@material-ui/core/styles/withStyles';
import IconIdea from 'components/Icons/Idea';

const styles = theme => ({
  root: {
    flex: 'auto',
    width: 'initial',
  },
  title: {
    fontWeight: 100,
  },
  body: {
    paddingTop: theme.spacing(1),
  },
  typeidea: {
    '& > div': {
      position: 'relative',
      paddingLeft: theme.spacing(9),
      '& > svg': {
        width: theme.spacing(7),
        position: 'absolute',
        left: 0,
      },
    },
    '& h2': {
      fontSize: '2rem',
      fontWeight: 'normal',
      opacity: '.6',
    },
    '& h6': {
      color: '#795548',
      opacity: '.8',
    },
  },
});

const NoticeBox = ({ type, detail, classes }) => (
  <Grid container spacing={0} alignItems="center" justify="center" className={`${classes[`type${type}`]} ${classes.root}`}>
    <div>
      { type === 'idea' && <IconIdea />}
      <div>
        <Typography variant="h2" gutterBottom className={classes.title}>
          {detail.title}
        </Typography>
        <Divider light />
        <Typography variant="subtitle1" className={classes.body}>
          {detail.body}
          {detail.body === 'Credencial inválida' && (
            <span>
              <br />
              Vuelva a <Link to="/login">ingresar</Link> al sistema
            </span>
          )}
        </Typography>
      </div>
    </div>
  </Grid>
);

NoticeBox.propTypes = {
  type: PropTypes.oneOf(['error', 'info', 'idea']).isRequired,
  detail: PropTypes.shape({
    title: PropTypes.string,
    body: PropTypes.string,
  }).isRequired,
};

export default withStyles(styles)(NoticeBox);
