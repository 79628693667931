import { connect } from 'react-redux';
import { reduxForm } from 'redux-form/immutable';
import { bindActionCreators } from 'redux';
import withStyles from '@material-ui/core/styles/withStyles';

export default function binder(props) {
  let { component } = props;
  const reduxFormParams = props.reduxForm;

  if (reduxFormParams) {
    // const { asyncValidate } = reduxFormParams;
    // if (asyncValidate) {
    //   reduxFormParams.asyncValidate = values => validate(values, asyncValidate);
    // }

    // reduxFormParams.destroyOnUnmount = true;
    component = reduxForm({
      // enableReinitialize: true,
      // destroyOnUnmount: true,
      ...reduxFormParams,
    })(component);
  }

  if (props.styles) component = withStyles(props.styles, { name: props.stylesName })(component);

  let mapStateToProps = null;
  let mapDispatchToProps = null;
  if (props.states) {
    mapStateToProps = (state) => {
      const stateProps = {};
      Object.keys(props.states).forEach((stateName) => {
        if (stateName === 'initialValues') {
          stateProps[stateName] = state.getIn(props.states[stateName]);
        } else {
          const propStateName = props.states[stateName];
          if (typeof propStateName === 'function') {
            stateProps[stateName] = propStateName(state);
          } else {
            const get = state.getIn(props.states[stateName]);
            stateProps[stateName] = get;
            // if (props.states[stateName].length === 2) {
            //   stateProps[stateName] = {
            //     status: get.get('status'),
            //     statusText: get.get('statusText'),
            //     statusCode: get.get('statusCode'),
            //     response: get.get('response'),
            //   };
            // } else {
            //   stateProps[stateName] = get;
            // }
          }
        }
      });
      return stateProps;
    };
  }
  if (props.actions) {
    mapDispatchToProps = dispatch => ({ actions: bindActionCreators(props.actions, dispatch) });
  }
  if (props.actions || props.states) {
    component = connect(mapStateToProps, mapDispatchToProps, null)(component);
  }
  return component;
}
