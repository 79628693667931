import React from 'react';

const IconIdea = () => (
  <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path d="M314.667,391.467c6.4-21.333,19.2-40.533,36.267-55.467c32-26.667,52.267-67.2,52.267-112	c0-83.2-69.333-151.467-154.667-147.2C172.8,80,110.933,144,108.8,219.733C107.733,266.667,128,308.267,161.067,336	C179.2,350.933,192,370.133,198.4,391.467L314.667,391.467L314.667,391.467z" fill="#ffd15c" />
    <path d="M242.133,393.6h9.6l-42.667-158.933c1.067,0,2.133,0,3.2,0c5.333,0,10.667-2.133,14.933-6.4	c2.133-2.133,4.267-3.2,7.467-3.2c3.2,0,5.333,1.067,7.467,3.2c7.467,8.533,20.267,8.533,27.733,0c2.133-2.133,4.267-3.2,7.467-3.2	c2.133,0,5.333,1.067,7.467,3.2c4.267,4.267,8.533,6.4,14.933,6.4c1.067,0,2.133,0,3.2,0l-41.6,158.933h9.6l43.733-166.4	c0-2.133,0-4.267-2.133-5.333c-2.133-1.067-4.267,0-5.333,1.067c-2.133,2.133-4.267,3.2-6.4,3.2c-3.2,0-5.333-1.067-8.533-3.2	c-4.267-4.267-8.533-6.4-13.867-6.4s-9.6,2.133-13.867,6.4s-10.667,4.267-14.933,0c-3.2-4.267-8.533-6.4-13.867-6.4l0,0	c-5.333,0-10.667,2.133-13.867,6.4c-2.133,2.133-5.333,3.2-8.533,3.2c-2.133,0-5.333-1.067-6.4-3.2	c-1.067-1.067-3.2-2.133-5.333-1.067c-2.133,1.067-3.2,3.2-2.133,5.333L242.133,393.6z" fill="#fff" />
    <g fill="#344a5e">
      <path d="M222.933,489.6C228.267,502.4,241.067,512,256,512c14.933,0,27.733-9.6,33.067-22.4H222.933z" />
      <path d="M295.467,490.667H217.6c-10.667,0-19.2-8.533-19.2-19.2V390.4h116.267v81.067 C314.667,482.133,306.133,490.667,295.467,490.667z" />
    </g>
    <g fill="#415a6b">
      <path d="M313.6,430.933H198.4c-6.4,0-11.733-5.333-11.733-11.733l0,0c0-6.4,5.333-11.733,11.733-11.733 h115.2c6.4,0,11.733,5.333,11.733,11.733l0,0C325.333,425.6,320,430.933,313.6,430.933z" />
      <path d="M313.6,468.267H198.4c-6.4,0-11.733-5.333-11.733-11.733l0,0c0-6.4,5.333-11.733,11.733-11.733 h115.2c6.4,0,11.733,5.333,11.733,11.733l0,0C325.333,462.933,320,468.267,313.6,468.267z" />
    </g>
    <g fill="#ffd15c">
      <path d="M256,0c-5.333,0-10.667,4.267-10.667,10.667v36.267c0,5.333,4.267,10.667,10.667,10.667 c6.4,0,10.667-4.267,10.667-10.667V10.667C266.667,4.267,261.333,0,256,0z" />
      <path d="M113.067,65.067c-4.267-4.267-10.667-4.267-14.933,0s-4.267,10.667,0,14.933l25.6,25.6 c4.267,4.267,10.667,4.267,14.933,0s4.267-10.667,0-14.933L113.067,65.067z" />
      <path d="M80,212.267H43.733c-5.333,0-10.667,4.267-10.667,10.667l0,0c0,5.333,4.267,10.667,10.667,10.667H80 c5.333,0,10.667-4.267,10.667-10.667l0,0C90.667,217.6,85.333,212.267,80,212.267z" />
      <path d="M123.733,340.267l-25.6,25.6c-4.267,4.267-4.267,10.667,0,14.933s10.667,4.267,14.933,0l25.6-25.6 c4.267-4.267,4.267-10.667,0-14.933S128,336,123.733,340.267z" />
      <path d="M388.267,340.267C384,336,377.6,336,373.333,340.267c-4.267,4.267-4.267,10.667,0,14.933l25.6,25.6 c4.267,4.267,10.667,4.267,14.933,0s4.267-10.667,0-14.933L388.267,340.267z" />
      <path d="M468.267,212.267H432c-5.333,0-10.667,4.267-10.667,10.667l0,0c0,5.333,4.267,10.667,10.667,10.667 h36.267c5.333,0,10.667-4.267,10.667-10.667l0,0C478.933,217.6,474.667,212.267,468.267,212.267z" />
      <path d="M398.933,65.067l-25.6,25.6c-4.267,4.267-4.267,10.667,0,14.933c4.267,4.267,10.667,4.267,14.933,0 l25.6-25.6c4.267-4.267,4.267-10.667,0-14.933S403.2,60.8,398.933,65.067z" />
    </g>
  </svg>
);

export default IconIdea;
