import { fade } from '@material-ui/core/styles/colorManipulator';

const styles = theme => ({
  card: {
    width: '100%',
    boxShadow: 'none',
    '&:hover > div:first-of-type': {
      opacity: 1,
    },
  },
  cardActive: {
    boxShadow: '0px 0px 5px 0px rgb(26, 75, 160)',
    zIndex: 1,
    '& > div:first-of-type': {
      opacity: 1,
    },
  },
  cardContent: {
    paddingTop: 0,
  },
  cardContentHead: {
    '& > div': {
      paddingTop: '0!important',
      paddingBottom: '0!important',
    },
  },
  cardActions: {
    borderTop: `1px solid ${theme.app.colorLine}`,
  },
  dragWrap: {
    height: theme.spacing(3),
    marginRight: theme.spacing(-2),
    marginLeft: theme.spacing(-2),
    textAlign: 'center',
    cursor: 'move',
    position: 'relative',
    opacity: 0,
    zIndex: 1,
    '& svg': {
      opacity: 0.4,
    },
  },
  selectType: {
    '& > div ': {
      backgroundColor: fade(theme.palette.primary[700], 0.09),
      padding: '4px 8px 0 8px',
      marginTop: '-4px',
      borderRadius: '4px',
      '& > div': {
        margin: 0,
      },
      '& label + div > div:before': {
        display: 'none',
      },
    },
  },
  requiredSwitchWrap: {
    marginLeft: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    borderLeft: `1px solid ${theme.app.colorLine}`,
  },
  gridItemNoTB: {
    paddingTop: '0!important',
    paddingBottom: '0!important',
  },
  firstInput: {
    marginTop: 0,
    '& input': {
      fontSize: '20px',
      paddingTop: '17px',
    },
  },
  firstInputRead: {
    '& > div:before': {
      borderBottom: 'none',
    },
  },
  textRequest: {
    borderBottom: '1px dotted',
    opacity: 0.4,
    lineHeight: '31px',
    margin: 0,
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(3),
    boxShadow: 'none',
  },
  cardHover: {
    minHeight: theme.spacing(13),
    backgroundColor: 'rgba(251, 245, 180, 0.3)',
    width: '100%',
  },
  valueTypeSelect: {
    margin: 0,
    marginTop: '-16px',
  },
  valueTypeText: {
    margin: 0,
    opacity: 0.4,
    lineHeight: '30px',
    borderBottom: '1px dotted',
    '& h6': {
      margin: 0,
    },
  },
  cardDefault: {
    // backgroundColor: '#00aad333',
    marginBottom: '1px',
    '& > div:last-child': {
      pointerEvents: 'none',
      paddingBottom: theme.spacing(1),
      '& > div > div:first-child input': {
        padding: 0,
      },
      '& > div > div:not(:first-child)': {
        display: 'none',
      },
    },
  },
});

export default styles;
