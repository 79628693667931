const styles = theme => ({
    hdRoot: {
      boxShadow: `inset 0 -1px 0 ${theme.app.colorLine}`,
      padding: `0 ${theme.spacing(2)}px`,
    },
    hdRootCol1: {
      maxWidth: theme.spacing(18),
      width: '100%',
    },
    hdRootCol2: {
      maxWidth: theme.spacing(100),
      width: '100%',
    },
    hdRootCol3: {
        maxWidth: theme.spacing(20),
        width: '100%',
      },
    downXls: {
      paddingRight: theme.spacing(5),
      position: 'relative',
      '& > .svg': {
        position: 'absolute',
        right: 0,
        marginTop: -5,
        width: theme.spacing(4),
        height: theme.spacing(4),
        borderRadius: '50%',
        '&:hover': {
          backgroundColor: 'rgba(0, 96, 175, 0.08)',
          '&>svg': {
            color: theme.palette.primary[700],
          },
        },
        '&> svg': {
          marginTop: 5,
          fontSize: '1.3rem',
        },
      },
    },
  });
  
  export default styles;
  