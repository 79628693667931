import React from "react";
import { BlockPicker } from "react-color";

export default class Field extends React.Component {
  state = {
    selectedColor:'ffff'
  }
  componentWillMount(){
    const { input } = this.props
    this.setState({ selectedColor: input.value ? input.value.get('hex'): 'ffff'  });
  }
  
  
  handleChangeComplete = (color) =>{
    this.setState({ selectedColor: color.hex });
}
  render() {

   const {meta: { touched, error }, input,...custom} = this.props
    return ( 
    <BlockPicker
    width='100%'  
    triangle='hide'
    
    error={!!(touched && error)}
    {...input}
    {...custom}
    color= {this.state.selectedColor}
    onChangeComplete={ this.handleChangeComplete }/>

    );
  }
}
