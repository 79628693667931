import React from 'react';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import ReactTable from 'react-table';
import './react-table.css';

import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import IconButton from '@material-ui/core/IconButton';
import { resizeDelay } from 'helpers/utils';
import Loading from 'components/Loading';
import Header from './Header';
import Pagination from './pagination';

class TableList extends React.PureComponent {
  tableRef = React.createRef();

  headerRef = React.createRef();

  state = {
    tableHeight: 0,

    data: [],
    autoColumns:[],
    pages: null,
    pageSize: 50,
    loading: false,
  }
  componentDidMount() {
    
    resizeDelay.on(this, true);
    const table = this.tableRef.current;
    table.addEventListener('refresh', this.handleRefresh, false);
    table.addEventListener('filtered', this.handleFiltered, false);
  }

  componentWillUnmount() {
    resizeDelay.off(this);
    const table = this.tableRef.current;
    table.removeEventListener('refresh', this.handleRefresh, false);
    table.removeEventListener('filtered', this.handleFiltered, false);
  }

  onScreenResize() { // called by resizeDelay;
    const table = this.tableRef.current;
    const header = this.headerRef.current;
    const { tableHeight } = this.state;
    if (table) {
      const height = table.parentElement.offsetHeight - (header.offsetHeight + 36);
      if (tableHeight !== height) {
        this.setState({
          tableHeight: height,
        });
      }
    }
  }

  handleRefresh = () => {
    this.fetchData(this.lastStateTable);
  }


  fetchData = (state) => {
    const fetchColumns =[];
    this.lastStateTable = state;
    const {formatDataRow, handleListAsync, filtro } = this.props;
    const filter = filtro
    console.log(filter);
    const paramsFetchData =filter;
    const { pageSize, page } = state;
    this.setState({ loading: true });
    handleListAsync({
      pageSize,
      page,
      ...paramsFetchData,
    }).then((result) => {
      const { success, body: { pages, rows, total = 0 } } = result;
      if (success && rows[0]!==undefined) {
          let keyNames = Object.keys(rows[0]);
          keyNames.forEach(element => {
            if (!['_id','landing_id'].includes(element)) {
                if (element==='lprefix') {
                    fetchColumns.push({ 
                        accessor : 'lprefix', 
                        Header: 'landing',
                    }); 
                }else if(element==='createdAt'){
                    fetchColumns.push({ 
                        accessor : 'createdAt', 
                        Header: 'Creado en',
                    }); 
                }else{
                    fetchColumns.push({ 
                        accessor : element, 
                        Header: element,
                    });
                }
            }
          });
        this.setState({
          loading: false,
          data: (rows || []).map(formatDataRow),
          autoColumns:fetchColumns,
          pages,
          total,
        });
      } else {
        this.setState({
          loading: false,
          data: [],
          autoColumns:[],
          pages: 0,
          total: 0,
        });
      }
    });
  }

  render() {
    const {
      handleNew,handleNewTop, filterContent, title, handleEdit, noDataText
    } = this.props;
    const {
      tableHeight, data, pages, loading, pageSize, total,autoColumns
    } = this.state;
    return (
      <Grid
        container
        style={{
          padding: '16px',
          flex: 'auto',
        }}
      >
        <Grid
          id="table-list"
          container
          wrap="nowrap"
          direction="column"
          ref={this.tableRef}
        >
          <Grid item ref={this.headerRef} className="hdList">
            <Header
              title={title}
              handleNew={handleNew}
              handleNewTop={handleNewTop}
              filterContent={filterContent}
            />
          </Grid>

          <ReactTable
            resizable={false}
            sortable={false}
            columns={autoColumns}
            defaultPageSize={pageSize}
            style={{
              height: tableHeight,
            }}
            getTrGroupProps={(state, rowInfo) => ({
              onClick: () => handleEdit && handleEdit(rowInfo.original),
            })}
            manual
            data={data}
            pages={pages} // Display the total number of pages
            loading={loading} // Display the loading overlay when we need it
            showPageSizeOptions={false}
            totalRows={total}
            // showPageJump={false}
            onFetchData={this.fetchData}
            previousText="Anterior"
            nextText="Siguiente"
            loadingText="Cargando..."
            noDataText={loading ? '' : (noDataText || 'Sin datos')}
            pageText="Página"
            ofText="de"
            rowsText="filas"
            pageJumpText="Ir a la página"
            rowsSelectorText="filas por página"
            className={handleEdit && '-striped -highlight'}
            PaginationComponent={Pagination}
            PreviousComponent={props => (
              <IconButton {...props}>
                <KeyboardArrowLeftIcon />
              </IconButton>
            )}
            NextComponent={props => (
              <IconButton {...props}>
                <KeyboardArrowRightIcon />
              </IconButton>
            )}
            TrGroupComponent={({ children, className, ...rest }) => (children.length > 0
              ? (
                <div className={clsx('rt-tr-group', className)} role="rowgroup" {...rest}>
                  {children}
                </div>
              )
              : null
            )}
            LoadingComponent={({
              className, loading: isLoading, loadingText, ...rest
            }) => (
              <div className={clsx('-loading', { '-active': loading }, className)} {...rest}>
                <div className="-loading-inner">
                  <Loading />
                </div>
              </div>
            )}
          />
        </Grid>
      </Grid>
    );
  }
}

export default TableList;
