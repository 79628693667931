import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { fromJS } from 'immutable';

import clsx from 'clsx';
// import classnames from 'classnames';

const SelectMain = require('react-select');

const Select = SelectMain.default;
const AsyncSelect = SelectMain.Async;

const containerStyles = theme => ({
  formControl: {
    minWidth: theme.spacing(30),
    margin: `${theme.spacing(2)}px 0 ${theme.spacing(1)}px`,
    fontSize: '1rem',
    '&>div>div': {
      height: 'auto',
    },
  },
  noMargin: {
    margin: 'initial!important',
    '& > div': {
      // marginTop: '0!important',
    },
  },
  fullWidth: {
    minWidth: '100%',
  },
  label: {
    transform: 'translate(0, 1.5px) scale(0.75)',
    '& + div': {
      marginTop: theme.spacing(2),
    },
  },
  outlined: {
    margin: 0,
    '& > label': {
      transform: 'translate(8px, -6px) scale(0.75)',
      zIndex: 1,
      backgroundColor: 'white',
      padding: '0 5px',
      '& + div': {
        marginTop: 0,
      },
    },
  },
  error: {
    '&>label': {
      color: '#f44336',
    },
  },
});

const selectStyles = {
  control: (base, state) => {
    if (state.selectProps.outlined) {
      return {
        ...base,
        borderRadius: 4,
        minHeight: '40px',
        // border: '1px solid rgba(0, 0, 0, 0.23)',
      };
    }
    const error = !!state.selectProps.error;
    return {
      ...base,
      border: '0',
      boxShadow: 'none!important',
      backgroundColor: 'initial',
      minHeight: '32px',
      height: '32px',
      fontSize: '1rem',
      '&:hover:before': !error && {
        borderBottom: '1px dotted rgba(0, 0, 0, 1)',
      },
      '&:before': state.selectProps.basic ? {} : {
        left: '0',
        right: '0',
        bottom: '0',
        content: '""',
        position: 'absolute',
        transition: 'border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        borderBottom: error ? '2px solid #f44336' : state.isDisabled ? '1px dotted rgba(0, 0, 0, 0.2)' : '1px dotted rgba(0, 0, 0, 0.4)', // eslint-disable-line
        pointerEvents: 'none',
      },
      '&: after': state.selectProps.basic ? {} : {
        left: '0',
        right: '0',
        bottom: '0',
        content: '""',
        position: 'absolute',
        transform: state.isFocused ? 'scaleX(1)' : 'scaleX(0)',
        transition: 'transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
        borderBottom: error ? '2px solid #f44336' : '2px solid #004e9f',
        pointerEvents: 'none',
      },
    };
  },
  dropdownIndicator: (base, state) => ({
    ...base,
    color: state.selectProps.error ? '#f44336' : state.isDisabled ? '#c9c9c9' : 'initial', // eslint-disable-line
    padding: '6px 0 6px 2px',
  }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  menu: base => ({
    ...base,
    // borderRadius: 'initial',
    marginTop: 2,
    fontSize: '1rem',
  }),
  option: (base, state) => ({
    ...base,
    color: state.isSelected ? 'initial' : base.color,
    backgroundColor: state.isSelected ? 'rgba(80, 80, 112, 0.16)' : state.isFocused ? 'rgba(80, 80, 112, 0.08)' : 'transparent', // eslint-disable-line
  }),
  valueContainer: (base, state) => ({
    ...base,
    padding: 0,
    margin: 0,
    '& div:first-child': {
      margin: 0,
      color: state.isDisabled ? 'rgba(0, 0, 0, 0.38)' : state.selectProps.error ? '#f44336' : 'inherit', // eslint-disable-line
    },
  }),
};

// TODO trabajar htmlFor
const RenderSelectField = ({
  input,
  classes,
  label,
  margin,
  className,
  fullWidth,
  basic,
  componentProps,
  meta: { touched, error },

}) => {
  let selectComponent;
  const propsInherit = componentProps || {};

  const propsGeneric = {
    // defaultValue: input.value.toJS ? input.value.toJS() : input.value,
    // defaultValue: input.value,
    // onBlurResetsInput: false,
    // onCloseResetsInput: false,
    // defaultValue: input.value,
    // value: input.value,
    formatOptionLabel: option => {
      // console.log('formatOptionLabel', option);
      return option && (option.get ? option.get('label') : option.label);
    },
    getOptionLabel: option => {
      // console.log('getOptionLabel', option);
      return option && (option.get ? option.get('label') : option.label);
    },
    getOptionValue: option => {
      // console.log('getOptionValue', option);
      return option && (option.get ? option.get('value') : option.value);
    },
    // onBlur: () => input.onBlur(input.value),
    onChange: (newItem) => {
      if (propsInherit.onChangeDispatch) {
        propsInherit.onChangeDispatch(input, fromJS(newItem));
        // propsInherit.onChangeDispatch(newItem);
      } else if (input.onChange) {
        input.onChange(fromJS(newItem));
      }
    },
    components: { IndicatorSeparator: null },
    styles: selectStyles,
    noOptionsMessage: ({ inputValue }) => (inputValue && `Sin resultado para ${JSON.stringify(inputValue)}`) || 'Digite para obtener datos',
    // components: {}
  };

  if (!propsInherit.defaultValue) {
    propsGeneric.value = (input.value.toJS) ? input.value.toJS() : input.value;
  }

  propsInherit.placeholder = propsInherit.placeholder || 'Seleccionar...';

  if (propsInherit && propsInherit.loadOptions) { // eslint-disable-line
    selectComponent = (
      <AsyncSelect
        {...propsGeneric}
        {...propsInherit}
        error={touched && error}
        basic={basic}
      />
    );
  } else {
    selectComponent = (
      <Select
        {...propsGeneric}
        {...propsInherit}
        error={touched && error}
        basic={basic}
      />
    );
  }

  if (basic) {
    return selectComponent;
  }

  return (
    <FormControl
      className={
        clsx(
          classes.formControl,
          className,
          { [classes.noMargin]: margin === 'none' },
          { [classes.fullWidth]: fullWidth },
          { [classes.outlined]: propsInherit.outlined },
          { [classes.error]: touched && error },
        )
      }
    >
      {label && <InputLabel className={classes.label}>{label}</InputLabel>}
      {selectComponent}
    </FormControl>
  );
};

export { Select };

export default withStyles(containerStyles)(RenderSelectField);
