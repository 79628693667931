import { combineReducers } from 'redux-immutable';
import { connectRouter } from 'connected-react-router/immutable';
import { reducer as formReducer } from 'redux-form/immutable';
import { all } from 'redux-saga/effects';

import notificationReducer, { notificationSagas } from './notification';
import userReducer, { userSagas } from './user';
import appReducer, { appSagas } from './app';
import formaReducer, { formaSagas } from './forma';
import formaXReducer, { formaXSagas } from './formax';
import logReducer, { logSagas } from './log';
import imgReducer, { imgSagas } from './img';

export const rootReducers = history => combineReducers({
  router: connectRouter(history),
  form: formReducer,
  notification: notificationReducer,
  user: userReducer,
  app: appReducer,
  forma: formaReducer,
  formax: formaXReducer,
  log: logReducer,
  img: imgReducer,
});

export default function* rootSagas() {
  yield all([
    ...notificationSagas,
    ...userSagas,
    ...appSagas,
    ...formaSagas,
    ...formaXSagas,
    ...logSagas,
    ...imgSagas, 
  ]);
}
