import React from 'react';
import { Field } from 'redux-form/immutable';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

import RenderDateField from 'components/Form/DateField';
import RenderTextField from 'components/Form/TextField';
import RenderSelectField from 'components/Form/SelectField';
import binder from 'helpers/binder';

export default function createListSearchForm(mainProps) {
  /*
  mainInput,
  // mainInputComponent?,
  handleParentSubmit,
  binder
  */
  class ListSearchForm extends React.PureComponent {

    static propTypes = {
      handleSubmit: PropTypes.func.isRequired,
      classes: PropTypes.objectOf(PropTypes.any).isRequired,
      stateForm: PropTypes.objectOf(PropTypes.any), // eslint-disable-line
      stateUser: PropTypes.objectOf(PropTypes.any), // eslint-disable-line
    }

    state = {
      showMore: false,
    }

    componentWillMount = () => {
      const { stateForm } = this.props;
      if (!stateForm) {
        // this.props.initialize({ // eslint-disable-line
        //   dateFrom: getUnixTime(addDays(now, -28)),
        //   dateTo: getUnixTime(now),
        //   stations: stateUser.get('stations'),
        //   filter: '',
        // });
      }
    }

    handleSubmit = (values) => {
      this.props.handleParentSubmit(values); // eslint-disable-line
    }

    handleFilter = (event, isButton) => {
      const { handleSubmit } = this.props;
      if (event.which === 13 || isButton === true) {
        handleSubmit(this.handleSubmit)();
      }
    }

    handleToggle = () => this.setState({ showMore: !this.state.showMore }); // eslint-disable-line

    render() {
      const { classes, stateUser } = this.props; // eslint-disable-line
      const { mainInput } = mainProps;
      const { showMore } = this.state;

      // (mainInput.componentProps || mainInput).onKeyDown = this.handleFilter;
      return (
        <div className={classes.searchtWrap}>
          <div className={classes.inputWrap}>
            <Field
              {...mainInput}
              name="filter"
              onKeyDown={this.handleFilter}
              component={RenderTextField}
              className={clsx({ [classes.mainInputText]: !mainInput.componentProps })}
            />
            <IconButton aria-label="slide" size="small" onClick={this.handleToggle} color="primary">
              {showMore ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)}
            </IconButton>
          </div>
          {showMore && (
            <Card elevation={2} className={classes.formWrap}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={6} className={classes.gridItemField}>
                    <Field
                      label="Creado en"
                      name="dateFrom"
                      margin="normal"
                      placeholder="dd/mm/yyyy"
                      fullWidth
                      component={RenderDateField}
                    />
                  </Grid>
                  <Grid item xs={6} className={classes.gridItemField}>
                    <Field
                      label="al"
                      name="dateTo"
                      margin="normal"
                      placeholder="dd/mm/yyyy"
                      fullWidth
                      component={RenderDateField}
                    />
                  </Grid>
                  <Grid item xs={12} className={classes.gridItemField}>
                    <Field
                      label="Estación"
                      name="station"
                      margin="normal"
                      fullWidth
                      renderValue={input => input.value.get('label')}
                      componentProps={{
                        isSearchable: false,
                        isClearable: true,
                        // isMulti: true,
                        options: stateUser.get('stations'),
                        noOptionsMessage: () => 'Vacío',
                      }}
                      component={RenderSelectField}
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions>
                <Button variant="contained" color="primary" onClick={event => this.handleFilter(event, true)}>
                  Buscar
                </Button>
              </CardActions>
            </Card>
          )}
        </div>
      );
    }
  }

  mainProps.binder.styles = (theme) => ({ // eslint-disable-line
    searchtWrap: {
      position: 'relative',
    },
    mainInputText: {
      margin: 0,
      '& input': {
        padding: '10.5px',
      },
      '& label': {
        transform: 'translate(14px, 12px) scale(1)',
      },
    },
    inputWrap: {
      position: 'relative',
      '& > button': {
        position: 'absolute',
        right: theme.spacing(0.55),
        top: theme.spacing(0.55),
      },
    },
    formWrap: {
      position: 'absolute',
      background: 'white',
      zIndex: 1,
      overflow:'visible'
    },
  });

  mainProps.binder.stylesName = 'ListSearchForm'; // eslint-disable-line

  return binder({
    ...mainProps.binder,
    component: ListSearchForm,
  });
}
