const styles = theme => ({
  hdRoot: {
    boxShadow: `inset 0 -1px 0 ${theme.app.colorLine}`,
    padding: `0 ${theme.spacing(2)}px`,
  },
  hdRootCol1: {
    maxWidth: theme.spacing(18),
    width: '100%',
  },
  hdRootCol2: {
    maxWidth: theme.spacing(100),
    width: '100%',
  },
  bdWrap: {
    flex: 'auto',
    overflow: 'auto',
  },
  bdInner: {
    maxWidth: theme.spacing(100),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  bdInnerTable: {
    position: 'relative',
    padding: `${theme.spacing(3)}px ${theme.spacing(1)}px 0 ${theme.spacing(1)}px`,
  },
  hdLeftIcon: {
    marginRight: theme.spacing(1),
  },
  gridItemField: {
    paddingTop: '0!important',
    paddingBottom: '0!important',
  },
  hidden: {
    display: 'none',
  },
  hdLink: {
    color: theme.palette.primary[700],
    fontFamily: 'monospace',
    fontSize: '12px',
  },

  /* Design */
  dsBox: {
    boxShadow: `0px 0px 0px 1px ${theme.app.colorLine}`,
    '&$Error': {
      boxShadow: `0px 0px 0px 1px ${theme.palette.error.main}`,
    },
  },
  dsImage: {
    width: '100%',
    paddingBottom: '56.25%', /* 16:9 */
    position: 'relative',
    backgroundColor: theme.app.colorBackground,
    '& > div': {
      position: 'absolute',
      top: '0',
      bottom: '0',
      left: '0',
      right: '0',
      color: 'white',
      fontSize: '24px',
      textAlign: 'center',
      backgroundSize: 'contain',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      boxShadow: `0px 0px 0px 1px ${theme.app.colorLine}`,
    },
    '& + div': {
      paddingBottom: theme.spacing(2),
      '& > div': {
        marginTop: 0,
        '& label': {
          fontWeight: 500,
          transform: 'none',
          '& + h6': {
            marginTop: theme.spacing(3),
          },
        },
      },
    },
  },
  listRegisters: {
    height: '100%',
    '& .hdList': {
      display: 'none',
    },
    // '& > div': {
    //   padding: '0!important',
    //   '& > div:first-child': {
    //     display: 'none',
    //   },
    // },
  },
  embed: {
    fontFamily: 'monospace',
  },
  downXls: {
    paddingRight: theme.spacing(5),
    position: 'relative',
    '& > .svg': {
      position: 'absolute',
      right: 0,
      marginTop: -5,
      width: theme.spacing(4),
      height: theme.spacing(4),
      borderRadius: '50%',
      '&:hover': {
        backgroundColor: 'rgba(0, 96, 175, 0.08)',
        '&>svg': {
          color: theme.palette.primary[700],
        },
      },
      '&> svg': {
        marginTop: 5,
        fontSize: '1.3rem',
      },
    },
  },
});

export default styles;
