import React from "react";
import request from 'helpers/request';
import imgDefault from 'static/avatar/default.png'
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import ImageSearchOutlinedIcon from '@material-ui/icons/ImageSearchOutlined';
import { fromJS } from 'immutable';
const style ={
  hidden:{
  display:'none'
  },
  centrar:{
    textAlign: 'center',
  },
    large: {
    width: '55px',
    height:'55px',
    borderRadius:'50px',
  },

};
export default class OptionImage extends React.PureComponent {
fileRef = React.createRef();
  state={
    urlValue:imgDefault,
  }
  constructor(props) {
    super(props);
    this.Upload = this.Upload.bind(this);
  }

handleClick=()=>{
  this.fileRef.current.click();
}
  Upload(e) {
    const { member, formid, fields, index } = this.props
    const initialIndex = index;

       //estructura de datas segun lo require el api
    let newMember = member.replace(".","-")
    newMember = newMember.replace("[","")
    newMember = newMember.replace("]","")
    newMember = newMember.replace("[","")
    newMember = newMember.replace("]","")
    newMember = newMember + '-' + formid
    let file = e.target.files[0]
    if (file) {
      const name = file.name
      const lastDot = name.lastIndexOf('.')
      const ext = name.substring(lastDot + 1)
      let data = new FormData();
      data.append("file", file, newMember+"."+ext)
      let Copyfile = data.get("file")
      let formData = new FormData()
      const fileValues = ({
        imgtop:Copyfile
      })
      formData.append('imgtop', fileValues['imgtop'])
    //---------------------------------------------------

//request para grabar la Imagen

        request({
          api: 'form/save-img',
          method: 'POST',
          formData
        }).then((result) =>{
          // setamos los datos
          const newItem = fromJS({
            label: fields.get(initialIndex).get('label')?fields.get(initialIndex).get('label'):"",
            value: fields.get(initialIndex).get('value')?fields.get(initialIndex).get('value'):"",
            imgTop: result.body.urlImgTop,
            description: fields.get(initialIndex).get('description')?fields.get(initialIndex).get('description'):"",
          });
          fields.remove(initialIndex);
          fields.splice(initialIndex,0,newItem);
          document.getElementById(member+'-img').src=fields.get(initialIndex).get('imgTop');
        });
      }
      this.fileRef.current.value= null;
  } 
  render() {
    const { member }= this.props
    return (
      <>
    <Grid style={style.centrar} item xs={2}>
    <img id={member+'-img'} src={this.state.urlValue} alt="imgTop" style={style.large}/>
    <Chip
        icon={<ImageSearchOutlinedIcon />}
        label="Tamaño: 81x81px"
        onClick={this.handleClick}
      />
    <input type="file" style={style.hidden} onChange={this.Upload} accept="image/jpeg, image/png"  ref={this.fileRef} />
    </Grid>
    </>
    )
  }
}
