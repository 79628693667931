import React from 'react';
import { Field } from 'redux-form/immutable';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import RenderTextField from 'components/Form/TextField';
import binder from 'helpers/binder';

export default function createListSearchForm(mainProps) {
  /*
  mainInput,
  // mainInputComponent?,
  handleParentSubmit,
  binder
  */
  class ListSearchForm extends React.PureComponent {
    static propTypes = {
      handleSubmit: PropTypes.func.isRequired,
      classes: PropTypes.objectOf(PropTypes.any).isRequired,
      stateForm: PropTypes.objectOf(PropTypes.any), // eslint-disable-line
    }

    handleSubmit = (values) => {
      this.props.handleParentSubmit(values); // eslint-disable-line
    }

    handleFilter = (event) => {
      const { handleSubmit } = this.props;
      if (event.which === 13) {
        handleSubmit(this.handleSubmit)();
      }
    }

    render() {
      const { classes } = this.props;
      const { mainInput } = mainProps;

      // (mainInput.componentProps || mainInput).onKeyDown = this.handleFilter;
      return (
        <div className={classes.searchtWrap}>
          <div className={classes.inputWrap}>
            <Field
              {...mainInput}
              name="filter"
              onKeyDown={this.handleFilter}
              component={RenderTextField}
              className={clsx({ [classes.mainInputText]: !mainInput.componentProps })}
            />
          </div>
        </div>
      );
    }
  }

  mainProps.binder.styles = (theme) => ({ // eslint-disable-line
    searchtWrap: {
      position: 'relative',
    },
    mainInputText: {
      margin: 0,
      '& input': {
        padding: '10.5px',
      },
      '& label': {
        transform: 'translate(14px, 12px) scale(1)',
      },
    },
    inputWrap: {
      position: 'relative',
      '& > button': {
        position: 'absolute',
        right: theme.spacing(0.55),
        top: theme.spacing(0.55),
      },
    },
  });

  mainProps.binder.stylesName = 'ListSearchForm'; // eslint-disable-line

  return binder({
    ...mainProps.binder,
    component: ListSearchForm,
  });
}
