import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';
import { put, takeLatest, call } from 'redux-saga/effects';
import { push } from 'connected-react-router/immutable';
import { REQUEST, FAILURE, SUCCESS } from 'helpers/constants';

import request from 'helpers/request';
import { types as typesNotification } from 'reducers/notification';
import { types as typesUser } from 'reducers/user';

// Constants
export const types = {
  RESOURCES_REQUEST: 'USER/RESOURCES_REQUEST',
  RESOURCES_SUCCESS: 'USER/RESOURCES_SUCCESS',
  RESOURCES_FAILURE: 'USER/RESOURCES_FAILURE',
};

// Actions
export const resourcesApp = createAction(types.RESOURCES_REQUEST);

const initialState = fromJS({
  resources: {
    status: null,
    data: null,
  },
});

// Reducer
export default handleActions(
  {
    [types.RESOURCES_SUCCESS]: (state, action) => {
      const newState = state.setIn(['resources', 'status'], SUCCESS);
      return newState.setIn(['resources', 'data'], fromJS(action.data));
    },
    [types.RESOURCES_REQUEST]: state => state.setIn(['resources', 'status'], REQUEST),
    [types.RESOURCES_FAILURE]: state => state.setIn(['resources', 'status'], FAILURE),
  },
  initialState,
);

// sagas
function* resourcesAppSaga() {
  const response = yield call(request, {
    api: 'app/resources',
    method: 'POST',
  });
  if (response.success) {
    yield put({ type: typesUser.LOGIN_SUCCESS, data: response.body.user });
    yield put({ type: types.RESOURCES_SUCCESS, data: response.body });
  } else {
    yield put({
      type: typesNotification.ADD,
      notification: {
        message: response.body,
        type: 'error',
      },
    });
    yield put({ type: types.RESOURCES_FAILURE });
    yield put(push('/login'));
  }
}

export const appSagas = [
  takeLatest(types.RESOURCES_REQUEST, resourcesAppSaga),
];
