import React from 'react';
import { Field, initialize } from 'redux-form/immutable';
import Typography from '@material-ui/core/Typography';
import RenderTextField from 'components/Form/TextField';
import RenderCheckField from 'components/Form/CheckField';
import RenderSelectField from 'components/Form/SelectField';
import Grid from '@material-ui/core/Grid';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';

import { SUCCESS, REQUEST } from 'helpers/constants';
import { getUser, saveUser } from 'reducers/user';
import { validate } from 'helpers/utils';
import Confirm from 'components/Confirm';
import binder from 'helpers/binder';
import BaseContainer from 'components/BaseContainer';
import styles from './EditStyles';

const nameForm = 'userEdit';

class UserEdit extends BaseContainer {
  componentWillMount = () => {
    const { actions, match: { params } } = this.props;
    actions.getUser(params.userId);
  }

  componentWillReceiveProps = (nextProps) => {
    const {
      stateSecond, dispatch, match, history,
    } = this.props;
    const { stateSecond: stateSecondNew } = nextProps;
    const statusSecond = (stateSecond && stateSecond.get('status')) || false;
    const statusSecondNew = (stateSecondNew && stateSecondNew.get('status')) || false;
    if (statusSecond === REQUEST && statusSecondNew === SUCCESS) {
      if (match.params.userId === 'new') {
        history.replace(`${match.url.replace('new', stateSecondNew.getIn(['data', '_id']))}`);
      }
      dispatch(initialize(nameForm, stateSecondNew.get('data')));
    }
  }

  handleShowMessage = params => (
    Confirm(params.message, {
      title: params.title,
      hideCancel: true,
    })
  );

  handleSubmit = (values) => {
    const { actions } = this.props;
    actions.saveUser(values.toJS());
  }

  toRenderTitlePage = () => 'Usuarios';

  toRender() {
    const { classes, stateResourcesData, dirty, handleSubmit } = this.props; // eslint-disable-line
    return (
      <React.Fragment>
        <Grid
          item
          container
          alignItems="center"
          wrap="nowrap"
          justify="space-between"
          spacing={0}
          className={classes.hdRoot}
        >
          <IconButton
            aria-label="Return"
            className={classes.backIcon}
            component={Link}
            to="/users"
            edge="start"
            title="Volver a lista de formularios"
          >
            <ArrowBackIcon />
          </IconButton>

          <Button
            variant="contained"
            color="primary"
            disabled={!dirty}
            onClick={() => handleSubmit(this.handleSubmit)()}
          >
            <SaveIcon className={classes.hdLeftIcon} />
            Guardar
          </Button>
        </Grid>
        <Grid
          container
          justify="center"
          alignContent="flex-start"
          className={classes.bdWrap}
          id="bodyForm"
        >
          <Grid container className={classes.bdInner} spacing={2}>
            <Grid item xs={12} className={classes.gridItemField}>
              <Typography variant="button" display="block" style={{ marginTop: '24px' }}>
                Datos personales
              </Typography>
            </Grid>
            <Grid item xs={6} className={classes.gridItemField}>
              <Field
                label="Nombres"
                name="names"
                margin="normal"
                fullWidth
                component={RenderTextField}
              />
            </Grid>
            <Grid item xs={6} className={classes.gridItemField}>
              <Field
                label="Apellidos"
                name="surnames"
                margin="normal"
                fullWidth
                component={RenderTextField}
              />
            </Grid>
            <Grid item xs={6} className={classes.gridItemField}>
              <Field
                label="Email"
                name="email"
                margin="normal"
                fullWidth
                component={RenderTextField}
              />
            </Grid>
            <Grid item xs={6} className={classes.gridItemField}>
              <Field
                label="Rol"
                name="role"
                margin="normal"
                fullWidth
                renderValue={input => input.value.get('label')}
                componentProps={{
                  isSearchable: false,
                  options: stateResourcesData.getIn(['types', 'roles']),
                  noOptionsMessage: () => 'Vacío',
                }}
                component={RenderSelectField}
              />
            </Grid>
            <Grid item xs={6} className={classes.gridItemField}>
              <Field
                label="Estaciones"
                name="stations"
                margin="normal"
                fullWidth
                renderValue={input => input.value.get('label')}
                componentProps={{
                  isSearchable: false,
                  isMulti: true,
                  options: (stateResourcesData.getIn(['stations']).toJS().map(item => ({ label: item.name, value: item.id }))),
                  noOptionsMessage: () => 'Vacío',
                }}
                component={RenderSelectField}
              />
            </Grid>
          </Grid>
          <Grid container className={classes.bdInner} spacing={2}>
            <Grid item xs={12} className={classes.gridItemField}>
              <Typography variant="button" display="block" gutterBottom>
                Datos de acceso
              </Typography>
            </Grid>
            <Grid item xs={6} className={classes.gridItemField}>
              <Field
                label="Nombre de usuario"
                name="username"
                margin="normal"
                fullWidth
                component={RenderTextField}
              />
            </Grid>
            <Grid item xs={6} className={classes.gridItemField}>
              <Field
                label="Contraseña"
                placeholder="Dejar en blanco si no desea cambiar"
                name="password"
                margin="normal"
                type="password"
                fullWidth
                component={RenderTextField}
              />
            </Grid>
          </Grid>
          <Grid container className={classes.bdInner} spacing={2}>
            <Grid item xs={4} className={classes.gridItemField}>
              <Field
                label="Activo"
                name="isActive"
                component={RenderCheckField}
              />
            </Grid>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

const validates = (values, props) => {
  const { match } = props;
  let errors = {};
  errors = validate.isEmpty('names,email,username,role,stations', values, errors);
  if (match.params.userId && match.params.userId === 'new') {
    if (!(values.get('password') || '').trim()) {
      errors.password = 'error';
    }
  }
  return errors;
};


export default binder({
  styles,
  stylesName: nameForm,
  reduxForm: {
    form: nameForm,
    validate: validates,
    // keepDirtyOnReinitialize: true,
  },
  states: {
    stateMain: ['user', 'get'],
    stateSecond: ['user', 'save'],
    stateResourcesData: ['app', 'resources', 'data'],
    initialValues: ['user', 'get', 'data'],
  },
  actions: { getUser, saveUser },
  component: UserEdit,
});
