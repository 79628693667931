const styles = theme => ({
  hdRoot: {
    boxShadow: `inset 0 -1px 0 ${theme.app.colorLine}`,
    padding: `0 ${theme.spacing(2)}px`,
  },
  bdWrap: {
    flex: 'auto',
    overflow: 'auto',
  },
  bdInner: {
    maxWidth: theme.spacing(100),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  hdLeftIcon: {
    marginRight: theme.spacing(1),
  },
  gridItemField: {
    paddingTop: '0!important',
    paddingBottom: '0!important',
  },

  /* Design */
  dsImage: {
    width: '100%',
    paddingBottom: '56.25%', /* 16:9 */
    position: 'relative',
    backgroundColor: theme.app.colorBackground,
    '& > div': {
      position: 'absolute',
      top: '0',
      bottom: '0',
      left: '0',
      right: '0',
      color: 'white',
      fontSize: '24px',
      textAlign: 'center',
      backgroundSize: 'contain',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      boxShadow: `inset ${theme.shadows[0]}`,
    },
    '& + div': {
      paddingBottom: theme.spacing(2),
      '& > div': {
        marginTop: 0,
        '& label': {
          fontWeight: 500,
          transform: 'none',
          '& + h6': {
            marginTop: theme.spacing(3),
          },
        },
      },
    },
  },
});

export default styles;
