import React from 'react';
import { Route, Switch } from 'react-router-dom';

import NotFound from 'containers/Error/E404';
import List from './List';
import Edit from './Edit';

const indexForm = (props) => {
  const { match } = props; // eslint-disable-line
  return (
    <Switch>
      <Route exact path={match.url} component={List} />
      <Route path={`${match.url}/:formId/:formFormat`} component={Edit} />
      <Route component={NotFound} />
    </Switch>
  );
};

// Module exports
export default indexForm;
