import React from 'react';
import { Field, FieldArray } from 'redux-form/immutable';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import clsx from 'clsx';
import Radio from '@material-ui/core/Radio';
import RenderTextField from 'components/Form/TextField';
import RenderSwitchField from 'components/Form/SwitchField';
import FieldTypeOptionsTop from './FieldTypeOptionsTop';


class EditFieldsCardTop extends React.PureComponent {
  state = {
    typeField: false,
  }

  componentWillMount = () => {
    const { fields, index } = this.props;
    this.setState({ typeField: fields.get(index).getIn(['type', 'value']) });
  }

  componentWillReceiveProps = (nextProps) => {
    const { fields, index } = nextProps;
    this.setState({ typeField: fields.get(index).getIn(['type', 'value']) });
  }

  render() {
    const {
      classes, member, indexActive, index, fields, formid 
    } = this.props;
    const readOnly = indexActive !== index;
    const { typeField } = this.state;
    let content = null;
    switch (typeField) {
      case 3:
        content = (
          <Grid item xs={12}>
            <FieldArray
              typeField={typeField}
              readOnly={readOnly}
              formid={formid}
              otherButton
              renderIcon={() => (<Radio disabled />)}
              name={`${member}.answer`}
              component={FieldTypeOptionsTop}
              handleShowMessage={this.handleShowMessage}
            />
          </Grid>
        );
        break;
      default:
        content = null;
    }

    return (
      <>
        <CardContent className={clsx(classes.cardContent, 'bodyFormItem')}>
          <Grid container spacing={3} className={clsx({ [classes.cardContentHead]: readOnly })}>
            <Grid item xs={readOnly ? 12 : 12}>
              <Field
                placeholder="Nombre de Campo"
                name={`${member}.question`}
                margin="normal"
                className={clsx('input-question', classes.firstInput, { [classes.firstInputRead]: readOnly })}
                fullWidth
                component={RenderTextField}
              />
            </Grid>
            {content}
          </Grid>
        </CardContent>
        {!readOnly && (
          <CardActions className={classes.cardActions}>
            <div style={{ flex: 'auto' }} />
            <IconButton onClick={() => fields.remove(index)}>
              <DeleteIcon />
            </IconButton>
            <div className={classes.requiredSwitchWrap}>
              <Field
                label="Obligatorio"
                name={`${member}.required`}
                component={RenderSwitchField}
              />
            </div>
          </CardActions>
        )}
      </>
    );
  }
}


export default EditFieldsCardTop;
