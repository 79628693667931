import React from 'react';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
});

const RenderSwitchField = ({
  input, label, classes, meta: { touched, error }, ...custom // eslint-disable-line
}) => {
  // const defaultProps = {};
  // const value = (input.value || 0).toString();
  // if (valueType === 'number') {
  //   defaultProps.onChange = (event, checked) => {
  //     input.onChange(checked ? 1 : 0);
  //   };
  //   defaultProps.onBlur = (event) => {
  //     input.onChange(event.target.checked ? 1 : 0);
  //   };
  // }
  return (
    <FormControlLabel
      label={label}
      control={(
        <Switch
          checked={!!input.value}
          // error={!!touched && !!error}
          {...input}
          {...custom}
          value
          color="primary"
        />
      )}
    />
  );
};

export default withStyles(styles)(RenderSwitchField);
