import localStore from 'helpers/localStore';

const b64EncodeUnicode = param =>
  btoa(encodeURIComponent(param).replace(/%([0-9A-F]{2})/g, (match, p1) => String.fromCharCode(`0x${p1}`))); // eslint-disable-line

const request = (props) => { // api, method, authorization, callback
  const { api } = window.globalParamsForm;
  const { body, authorization, formData } = props;
  // JSON.stringify(props.body)
  let params = body;
  let token = `Bearer ${localStore.get('token')}`;
  if (authorization) {
    params = null;
    token = b64EncodeUnicode(`${authorization.username}:${authorization.password}`);
  }
  return fetch(`${api}${props.api}`, {
    method: props.method,
    body: formData || JSON.stringify(params),
    headers: {
      Authorization: authorization ? `Basic ${token}` : token,
      // 'Content-Type': 'application/json',
    },
  })
    .then(response => response.json())
    .then((response) => {
      if (response.status === 'OK') {
        return {
          success: true,
          body: response.body,
        };
      }
      return {
        success: false,
        body: response.body,
      };
    })
    .catch(() => ({
      success: false,
      body: 'Ha ocurrido una inconsistencia, vuelva a intentar en unos minutos',
    }));
};


export default request;
