import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CheckIcon from '@material-ui/icons/Check';
import Grid from '@material-ui/core/Grid';
import WarningIcon from '@material-ui/icons/Warning';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import { hexToRgb } from '@material-ui/core/styles/colorManipulator';
import PropTypes from 'prop-types';
import Immutable from 'immutable';


import Timer from 'helpers/timer';
import { actionsNotification } from 'reducers/notification';

const styles = theme => ({
  main: {
    minHeight: 40,
    border: 'none',
    zIndex: 2,
    boxShadow: theme.shadows[10],
    background: 'white',
    marginTop: theme.spacing(1),
  },
  'status-default': {

  },
  'status-info': {
    boxShadow: `${theme.shadows[10]}, inset 4px 0px 0px 0px ${hexToRgb(theme.palette.primary.dark)}`,
    '& > svg': {
      fill: theme.palette.primary.dark,
    },
  },
  'status-success': {
    boxShadow: `${theme.shadows[10]}, inset 4px 0px 0px 0px #00C853`,
    '& > svg': {
      fill: '#00C853',
    },
  },
  'status-warning': {
    boxShadow: `${theme.shadows[10]}, inset 4px 0px 0px 0px #FFC107`,
    '& > svg': {
      fill: '#FFC107',
    },
  },
  'status-error': {
    boxShadow: `${theme.shadows[10]}, inset 4px 0px 0px 0px ${hexToRgb(theme.palette.error.dark)}`,
    '& > svg': {
      fill: theme.palette.error.dark,
    },
  },
  imageContainer: {
    padding: `${theme.spacing(1)}px 0 ${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  image: {
    borderRadius: 40,
    width: 40,
    height: 40,
    backgroundSize: 'cover',
  },
  icon: {
    margin: `${theme.spacing(1.5)}px 0 ${theme.spacing(1.5)}px ${theme.spacing(1.75)}px`,
    fontSize: 20,
    alignSelf: 'flex-start',
  },
  buttonClose: {
    height: theme.spacing(3.5),
    width: theme.spacing(3.5),
    float: 'left',
    padding: 0,
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    alignSelf: 'flex-start',
  },
  meta: {
    flex: 'auto',
    padding: `${theme.spacing(1.5)}px`,
  },
});

function createTimer(dismissAfter, callback) {
  if (dismissAfter > 0) {
    return new Timer(dismissAfter, callback);
  }
  return null;
}

class Notification extends PureComponent {
  // static defaultProps = {
  //   notification2: {},
  // };

  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    notification: PropTypes.instanceOf(Immutable.Map).isRequired,
    deleteNotification: PropTypes.func.isRequired,
  }

  constructor(props) {
    const { notification } = props;
    super(props);
    this.timer = createTimer(notification.get('dismissAfter'), this.handleRemove);
  }

  handleRemove = () => {
    const { deleteNotification, notification } = this.props;
    deleteNotification(notification.get('id'));
  }

  handlePauseTimer = () => {
    if (this.timer) {
      this.timer.pause();
    }
  }

  handleResumeTimer = () => {
    if (this.timer) {
      this.timer.resume();
    }
  }

  render() {
    const { classes, notification } = this.props;
    const title = notification.get('title');
    const message = notification.get('message');
    const type = notification.get('type');

    let iconTag = null;
    let messageTag = null;
    let titleTag = null;

    const notificationClass = [
      classes.main,
      classes[`status-${type}`],
    ].join(' ');

    if (this.timer) {
      this.handleResumeTimer();
    }

    if (type === 'info') {
      iconTag = <InfoOutlinedIcon className={classes.icon} />;
    } else if (type === 'success') {
      iconTag = <CheckIcon className={classes.icon} />;
    } else if (type === 'warning') {
      iconTag = <WarningIcon className={classes.icon} />;
    } else if (type === 'error') {
      iconTag = <RemoveCircleOutlineIcon className={classes.icon} />;
    }

    if (typeof message === 'string' && /<[a-z][\s\S]*>/i.test(message)) { // is html string
      messageTag = <div dangerouslySetInnerHTML={{ __html: message }} />;  // eslint-disable-line 
    } else {
      messageTag = <div>{message}</div>;
    }

    if (typeof title === 'string' && /<[a-z][\s\S]*>/i.test(title)) { // is html string
      titleTag = (
        <Typography
          variant="button"
          dangerouslySetInnerHTML={{ __html: title }}
          gutterBottom
        />
      );
    } else if (title) {
      titleTag = <Typography variant="button" gutterBottom>{title}</Typography>;
    }

    return (
      <Grid
        container
        alignItems="center"
        wrap="nowrap"
        justify="space-between"
        spacing={0}
        onMouseEnter={this.handlePauseTimer}
        onMouseLeave={this.handleResumeTimer}
        className={notificationClass}
      >
        {iconTag}
        <div className={classes.meta}>
          {titleTag}
          {messageTag}
        </div>
        <IconButton className={classes.buttonClose} aria-label="Close" onClick={this.handleRemove}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </Grid>
    );
  }
}

export default connect(null, {
  deleteNotification: actionsNotification.deleteNotification,
})(withStyles(styles)(Notification));
