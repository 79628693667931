import React from 'react';

import ListSearch from 'components/ListSearch';
import { listFormaXAsync } from 'reducers/formax';
import { formatFromUnix } from 'helpers/utils';

class ListRegisters extends React.PureComponent {
  handleDownload = () => {
    console.log('11111');
  }

  render() {
    const { fields, fieldsTop, station, form } = this.props;
    let columns = fields
      .map(field => ({ accessor: field.get('id'), Header: field.get('question') }))
      .toJS();
    if (fieldsTop) {
      const columnsTop = fieldsTop
      .map(field => ({ accessor: field.get('id'), Header: field.get('question') }))
      .toJS();
      for (let index = 0; index < columnsTop.length; index++) {
        columns.push(columnsTop[index]);
      }
    }
    columns.push({ accessor: 'time', Header: 'Fecha' });
    return (
      <React.Fragment>
        <ListSearch
          handleListAsync={listFormaXAsync}
          columns={columns}
          paramsFetchData={{
            station,
            form,
          }}
          noDataText="No se tiene participantes registrados"
          formatDataRow={row => ({
            ...row,
            time: formatFromUnix(row.createAt, 'dd/MM/yyyy HH:mm:ss'),
          })}
        />
      </React.Fragment>
    );
  }
}
export default ListRegisters;
