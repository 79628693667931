import React from 'react';
import Notice from 'components/Notice';

const E404 = () => (
  <Notice
    type="info"
    detail={{
      title: '404',
      body: 'El contenido solicitado no existe.',
    }}
  />
);

export default E404;
