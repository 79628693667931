import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

import confirmable from './confirmable';

class Confirmation extends React.PureComponent {
  render() {
    const {
      confirmation, show, proceed, cancel, hideCancel, alternateContent, title, classes, // eslint-disable-line
    } = this.props;

    return (
      <Dialog
        open={show}
        disableBackdropClick
        disableEscapeKeyDown
        // maxWidth="xs"
        aria-labelledby="confirmation-dialog-title"
        classes={{ root: classes.root, paper: classes.paper }}
      >
        <DialogTitle id="confirmation-dialog-title">{title || 'Atención'}</DialogTitle>
        <DialogContent>
          <p>{confirmation}</p>
          {alternateContent}
        </DialogContent>
        <DialogActions>
          {!hideCancel && (
            <Button onClick={cancel}>
              Cancelar
            </Button>
          )}
          <Button onClick={proceed} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const styles = () => ({
  root: {
    '& > div:first-child': {
      backgroundColor: 'rgba(9, 30, 67, 0.3)',
    },
  },
  paper: {
    boxShadow: '0px 0px 25px 0px rgba(9, 30, 67,.1)',
  },
});

export default confirmable(withStyles(styles)(Confirmation));
