import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  default: {
    width: '100%',
    margin: `${theme.spacing(2)}px 0 ${theme.spacing(1)}px`,
    '& > label': {
      transform: 'translate(0, 1.5px) scale(0.75)',
      transformOrigin: 'top left',
    },
    '& > h6': {
      marginTop: `${theme.spacing(2)}px`,
      marginBottom: 0,
      lineHeight: `${theme.spacing(4)}px`,
    },
  },
  nomargin: {
    margin: 0,
  },
});

const RenderReadField = ({
  input, margin, label, classes, className, renderValue, basic, fields, contentIsEmpty, variant, // eslint-disable-line
}) => (basic ? (
  <Typography variant={variant || 'subtitle1'}>
    {(renderValue && renderValue(input)) || (input.value || contentIsEmpty)}
  </Typography>
) : (
  <FormControl
    className={`${classes.default} ${margin === 'none' ? classes.nomargin : ''} ${className}`}
  >
    <InputLabel>{label}</InputLabel>
    <Typography variant={variant || 'subtitle1'} gutterBottom>
      {(renderValue && renderValue(input || fields)) || (input.value || contentIsEmpty)}
    </Typography>
  </FormControl>
));

export default withStyles(styles)(RenderReadField);
