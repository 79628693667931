import React from 'react';

import { listUserAsync } from 'reducers/user';
import ListSearch from 'components/ListSearch';
import BaseContainer from 'components/BaseContainer';
import createListSearchForm from 'components/ListSearch/formUser';

const ListFilter = createListSearchForm({
  mainInput: {
    label: 'Buscar',
    name: 'filter',
    fullWidth: true,
    placeholder: 'Buscar usuario...',
    variant: 'outlined',
  },
  binder: {
    reduxForm: {
      form: 'userListFilterForm',
      destroyOnUnmount: false,
    },
  },
});

class UserList extends BaseContainer {
  ignoreStatus = true;

  handleFilter = (values) => {
  }

  handleNewUser = () => {
    const { history, match } = this.props;
    history.push(`${match.url}/new`);
  }

  handleEdit = (item) => {
    const { history, match } = this.props;
    history.push(`${match.url}/${item.id}`);
  }

  toRenderTitlePage = () => 'Lista de usuarios';

  toRender() {
    return (
      <React.Fragment>
        <ListSearch
          title="Usuarios"
          handleNew={this.handleNewUser}
          handleEdit={this.handleEdit}
          handleListAsync={listUserAsync}
          columns={[
            {
              accessor: 'name',
              Header: 'Nombre',
            },
            {
              accessor: 'username',
              Header: 'Usuario',
            },
            {
              accessor: 'isActive',
              Header: 'Activo',
            },
          ]}
          formatDataRow={row => ({
            id: row._id, // eslint-disable-line
            name: `${row.names} ${row.surnames}`,
            username: row.username,
            isActive: row.isActive ? 'SI' : 'NO',
          })}
          filterContent={<ListFilter handleParentSubmit={this.handleFilter} />}
        />
      </React.Fragment>
    );
  }
}

export default UserList;
