/* eslint-disable react/prop-types */
import React from 'react';
import TextField from '@material-ui/core/TextField';

export const Field = ({ input, meta: { touched, error }, ...custom }) => (
  <TextField
    error={!!(touched && error)}
    {...input}
    {...custom}
  />
);

export default Field;
