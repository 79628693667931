import React from 'react';
import ReactDOM from 'react-dom';
// import createBrowserHistory from 'history/createBrowserHistory';
import { createBrowserHistory } from 'history';
// import { BrowserRouter as Router } from 'react-router-dom';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router/immutable';
import CssBaseline from '@material-ui/core/CssBaseline';

import { MuiThemeProvider } from '@material-ui/core/styles';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from 'date-fns/locale/es';

import theme from './theme';

import configureStore from './configureStore';
import Routes from './routes';
import Wrap from './containers/Wrap';
import * as serviceWorker from './serviceWorker';

import './base.css';

const history = createBrowserHistory();
const store = configureStore({}, history);

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
        <MuiThemeProvider theme={theme}>
          <CssBaseline>
            <Wrap>
              <Router history={history}>
                <Routes />
              </Router>
            </Wrap>
          </CssBaseline>
        </MuiThemeProvider>
      </MuiPickersUtilsProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('app'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
