import React from 'react';
import Notifications from 'components/Notifications';

const Wrap = ({ children }) => ( // eslint-disable-line
  <>
    {children}
    <Notifications />
  </>
);

// Module exports
export default Wrap;
