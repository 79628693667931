import React from 'react';

import { formatFromUnix } from 'helpers/utils';
import ListSearch from 'components/ListSearch';
import BaseContainer from 'components/BaseContainer';
import { listLogAsync } from 'reducers/log';


class LogList extends BaseContainer {
  ignoreStatus = true;

  componentWillMount = () => {
  }

  handleNew = () => {
    // const { history, match } = this.props;
    // history.push(`${match.url}/new`);
  }

  handleEdit = () => {
    // const { history, match } = this.props;
    // history.push(`${match.url}/${item.get('_id')}`);
  }

  toRenderTitlePage = () => 'Log';

  toRender() {
    const { stateMain } = this.props; // eslint-disable-line
    return (
      <React.Fragment>
        <ListSearch
          title="Log"
          handleListAsync={listLogAsync}
          columns={[
            {
              accessor: 'action',
              Header: 'Acción',
            },
            {
              accessor: 'username',
              Header: 'Usuario',
            },
            {
              accessor: 'description',
              Header: 'Descripción',
            },
            {
              accessor: 'date',
              Header: 'Fecha',
            },
            {
              accessor: 'id',
              Header: 'LogId',
            },
          ]}
          formatDataRow={row => ({
            action: `${row.action} » ${row.model}`.toUpperCase(),
            username: row.author.label,
            description: row.description,
            date: formatFromUnix(row.createAt, 'dd/MM/yyyy HH:mm:ss'),
            id: row._id, // eslint-disable-line
          })}
        />
      </React.Fragment>
    );
  }
}

export default LogList;
