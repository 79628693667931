import React from 'react';
import { Route, Switch } from 'react-router-dom';

import NotFound from 'containers/Error/E404';
import List from './List';

const indexLead = (props) => {
  const { match } = props; // eslint-disable-line
  return (
    <Switch>
      <Route exact path={match.url} component={List} />
      <Route component={NotFound} />
    </Switch>
  );
};

// Module exports
export default indexLead;
