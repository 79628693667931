const styles = theme => ({
  root: {
    height: theme.spacing(8),
    zIndex: 1,
    boxShadow: `inset 0 -1px 0 ${theme.app.colorLine}`,
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  brand: {
    '& > img, & > span': {
      verticalAlign: 'middle',
      display: 'inline-block',
    },
    '& img': {
      width: '100px',
    },
    '& sup': {
      color: '#424242',
      fontSize: 10,
      paddingLeft: 2,
    },

  },
  avatar: {
    cursor: 'pointer',
    backgroundColor: '#673ab7',
  },
  select: {
    marginLeft: theme.spacing(1),
    padding: 0,
    '&:before, &:after': {
      content: 'initial',
    },
    '& [role=button]': {
      paddingLeft: theme.spacing(2),
      fontSize: '22px',
      lineHeight: '26px',
      fontWeight: 100,
      '&:focus': {
        background: `${theme.app.fade(theme.app.colorDark, 0.05)}`,
      },
    },
  },
  username: {
    outline: 'none',
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(2)}px`,
  },
  avatarInner: {
    marginRight: theme.spacing(1),
    backgroundColor: '#673ab7',
  },
  names: {
    '& div': {
      lineHeight: 1.3,
    },
  },
  links: {
    paddingRight: theme.spacing(2),
    lineHeight: '38px',
    '& > a': {
      color: '#424242',
      padding: `0 ${theme.spacing(2)}px`,
      borderRadius: theme.spacing(1),
    },
  },
});

export default styles;
