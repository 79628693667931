import React, { PureComponent } from 'react';

const confirmable = Component => class extends PureComponent {
  state = {
    show: true,
  }

  dismiss = () => {
    this.setState({
      show: false,
    }, () => {
      this.props.dispose(); // eslint-disable-line
    });
  }

  cancel = (value) => {
    this.setState({
      show: false,
    }, () => {
      this.props.reject(value); // eslint-disable-line
    });
  }

  proceed = (value) => {
    this.setState({
      show: false,
    }, () => {
      this.props.resolve(value); // eslint-disable-line
    });
  }

  render() {
    return (
      <Component
        proceed={this.proceed}
        cancel={this.cancel}
        dismiss={this.dismiss}
        show={this.state.show} // eslint-disable-line
        {...this.props}
      />
    );
  }
};

export default confirmable;
