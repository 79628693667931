import React, { useRef } from 'react';
import Card from '@material-ui/core/Card';
import { useDrag, useDrop } from 'react-dnd';

const ItemTypes = {
  CARD: 'card',
};

let lastId = false;

const EditFieldsDrag = ({ id, moveCard, moveEndCard, children, propsCard, propsDrag }) => { // eslint-disable-line
  const ref = useRef(null);
  const refPreview = useRef(null);
  const [, drop] = useDrop({
    accept: ItemTypes.CARD,
    hover(item) {
      if (lastId !== id) {
        moveCard(item.id, id);
        lastId = id;
      }
    },
  });
  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: ItemTypes.CARD, id },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    end(item) {
      moveEndCard(item.id, lastId);
    },
  });
  const opacity = isDragging ? 0 : 1;
  // const opacity = isDragging ? 'none' : 'initial';
  drag(drop(ref));
  preview(refPreview);
  return (
    <Card ref={refPreview} style={{ opacity, height: opacity ? 'auto' : '16px' }} {...propsCard}>
      <div className={propsDrag.className} ref={ref}>{propsDrag.icon}</div>
      {children}
    </Card>
  );
};

export default EditFieldsDrag;
