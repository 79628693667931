import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import { REQUEST, FAILURE, SUCCESS } from 'helpers/constants';

import request from 'helpers/request';

// Constants
export const types = {
  SAVE_REQUEST: 'IMG/SAVE_REQUEST',
  SAVE_SUCCESS: 'IMG/SAVE_SUCCESS',
  SAVE_FAILURE: 'IMG/SAVE_FAILURE',

};

// Actions
export const saveImg= createAction(types.SAVE_REQUEST);
const initialState = fromJS({
  save: {
    status: null,
    data: null,
  }
});

// Reducer
export default handleActions(
  {
    [types.SAVE_SUCCESS]: (state, action) => {
      const newState = state.setIn(['save', 'status'], SUCCESS);
      return newState.setIn(['save', 'data'], fromJS(action.data));
    },  
    [types.SAVE_REQUEST]: state => state.setIn(['save', 'status'], REQUEST),
    [types.SAVE_FAILURE]: (state, action) => {
      const newState = state.setIn(['save', 'status'], FAILURE);
      return newState.setIn(['save', 'data'], fromJS(action.data));
  },
  [types.GET_SUCCESS]: (state, action) => {
    const newState = state.setIn(['get', 'status'], SUCCESS);
    return newState.setIn(['get', 'data'], fromJS(action.data));
  },
},
initialState,
);
export const saveImgSaga = props => request({
  api: 'form/save-img',
  method: 'POST',
  props
});
export const imgSagas = [
  takeLatest(types.SAVE_REQUEST, saveImgSaga)
];
