import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';
import {
  put, takeLatest, call,
} from 'redux-saga/effects';
import { REQUEST, FAILURE, SUCCESS } from 'helpers/constants';

import request from 'helpers/request';
import { types as typesNotification } from 'reducers/notification';

// Constants
export const types = {
  LIST_REQUEST: 'LEADS/LIST_REQUEST',
  LIST_SUCCESS: 'LEADS/LIST_SUCCESS',
  LIST_FAILURE: 'LEADS/LIST_FAILURE',
};

// Actions
export const listLeads = createAction(types.LIST_REQUEST);
const initialState = fromJS({
  list: {
    status: null,
    data: null,
  }
});

// Reducer
export default handleActions(
  {
    [types.LIST_SUCCESS]: (state, action) => {
      const newState = state.setIn(['list', 'status'], SUCCESS);
      return newState.setIn(['list', 'data'], fromJS(action.data));
    },
    [types.LIST_REQUEST]: state => state.setIn(['list', 'status'], REQUEST),
    [types.LIST_FAILURE]: (state, action) => {
      const newState = state.setIn(['list', 'status'], FAILURE);
      return newState.setIn(['list', 'data'], fromJS(action.data));
    }
  },
  initialState,
);

// sagas
function* listLeadsSaga({ payload }) {
  const response = yield call(request, {
    api: 'form/list',
    method: 'POST',
    body: payload,
  });

  if (response.success) {
    yield put({ type: types.LIST_SUCCESS, data: response.body });
  } else {
    yield put({
      type: typesNotification.ADD,
      notification: {
        message: response.body,
        type: 'error',
      },
    });
    yield put({ type: types.LIST_FAILURE, data: response.body });
  }
}

export const listLeadsAsync = props => request({
  api: `leads/list-leads`,
  method: 'POST', 
  body: props,
});
export const exportleadsCSVAsync = props => request({
  api: `report/get-lead-report?lprefix=${props.lprefix}&today=${props.today}`,
  method: 'POST',
  body: "",
});
export const listLandingsAsync = props => request({
  api: 'landing/list-landings',
  method: 'POST', 
  body: props,
});

export const leadsSagas = [
  takeLatest(types.LIST_REQUEST, listLeadsSaga),
];
