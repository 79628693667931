export const INITIAL = 'INITIAL';
export const REQUEST = 'REQUEST';
export const SUCCESS = 'SUCCESS';
export const FAILURE = 'FAILURE';
export const OTROS = 'OTROS';
export const ROLE_ADMIN = 'admin';
export const ROLE_EDITOR = 'editor';

export default {
  INITIAL,
  REQUEST,
  SUCCESS,
  FAILURE,
  OTROS,
  ROLE_ADMIN,
  ROLE_EDITOR,
};
