import React from 'react';
import { Link } from 'react-router-dom';
import {
  Fields, FieldArray, destroy, initialize,
} from 'redux-form/immutable';
import Grid from '@material-ui/core/Grid';
import Badge from '@material-ui/core/Badge';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import clsx from 'clsx';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import SorteoGanador from 'components/ListSearch/SorteoGanador';
import {
  KeyboardDatePicker,
} from '@material-ui/pickers';

import { SUCCESS, REQUEST } from 'helpers/constants';
import { tmpFiles, validate } from 'helpers/utils';
import binder from 'helpers/binder';
import { getForma, saveForma, exportCSVAsync } from 'reducers/forma';
import Confirm from 'components/Confirm';
import BaseContainer from 'components/BaseContainer';
import localStore from 'helpers/localStore';

import styles from './EditStyles';
import EditConfig from './EditConfig';
import EditDesign from './EditDesign';
import EditDesignTop from './EditDesignTop';
import EditFields from './EditFields';
import EditFieldsTop from './EditFieldsTop';
import ListRegisters from './ListRegisters';
import withDragDropContext from './DragDropContext';



const FieldArrayDnd = withDragDropContext(FieldArray);

const nameForm = 'formEdit';
let formatTopflag = false;

class FormEdit extends BaseContainer {

  wrapCardsRef = React.createRef();
  wrapCardsRef2 = React.createRef();
  formDown = React.createRef();

  state = {
    tabSelecIndex: 0,
    anchorEl: null,
    typeFile: 'xls',
    formatTop: false, // false estandar true formato top
    csvLoading: false
  }

  componentWillMount = () => {
    const { actions, match: { params } } = this.props;
    actions.getForma(params.formId);
    this.tmpFiles = tmpFiles(nameForm, true);
    // const isNew = params.formId === 'new';
      if (params.formFormat === 'top') {
        formatTopflag = true;
        this.setState({formatTop:true})
      }
      if (params.formFormat === 'estandar') {
        formatTopflag = false;
        this.setState({formatTop:false})
      }
  }

  componentWillReceiveProps = (nextProps) => {
    const {
      stateSecond, dispatch, match, history,
    } = this.props;
    const { stateSecond: stateSecondNew } = nextProps;
  
    const statusSecond = (stateSecond && stateSecond.get('status')) || false;
    const statusSecondNew = (stateSecondNew && stateSecondNew.get('status')) || false;
    if (statusSecond === REQUEST && statusSecondNew === SUCCESS) {
      if (match.params.formId === 'new') {
        history.replace(`${match.url.replace('new', stateSecondNew.getIn(['data', 'id']))}`);
      }
      this.tmpFiles = tmpFiles(nameForm, true);
      dispatch(initialize(nameForm, stateSecondNew.get('data')));
    }
  }

  componentWillUnmount = () => {
    const { dispatch, actions } = this.props;
    this.tmpFiles.destroy();
    dispatch(destroy(nameForm));
    actions.getForma({ initialize: true });
  }


  handleShowMessage = params => (
    Confirm(params.message, {
      title: params.title,
      hideCancel: true,
    })
  );

  handleChangeTab = (event, newValue) => this.setState({ tabSelecIndex: newValue })

  handleSubmit = (values) => {
    const { actions } = this.props;
    // cojemos archivos de variable global
    actions.saveForma({
      data: values.toJS(),
      files: window.tmpFiles[nameForm],
    });
  }

  handleDownload = (event) => {
    event.stopPropagation();

    this.setState({ anchorEl: event.currentTarget });
    // const { current } = this.formDown;
    // current.submit();
  }

  handleCloseMenu = (event, type) => {
    event.stopPropagation();
    this.setState({ anchorEl: null });
    if (type === 'csv' || type === 'xls') {
      const { current } = this.formDown;
      this.setState({ typeFile: type });
      current.submit();
    }
  }

  handleExportCsv = (event, idForm, today) => {
    if (event) { event.stopPropagation(); }
    const {csvLoading} = this.state;
    if (!csvLoading) {
      this.setState({ anchorEl: null, csvLoading: true });
      try {
        exportCSVAsync({
          idForm: idForm,
          today: today,
        }).then((result) => {
          if (result.success === true) {
            const { statusCode, file } = result.body;
            this.setState({ csvLoading: false })
            if (statusCode === 200) {
              var a = document.createElement("a");
              a.href = file;
              a.setAttribute("download", `data_forma_${idForm}.csv`);
              a.click();
            }
          }
        });
      } catch (error) {
        this.setState({ csvLoading: false })
        console.log('error', error);
      }
    }
  }

  setPickerStatus = (event) => {
    if (event) { event.stopPropagation(); }
    this.setState({ anchorEl: null });
  }

  handleDateChange = (date, idForm) => {
    if (date) {
      date = date.setHours(0, 0, 0, 0);
      this.handleExportCsv(null, idForm, date/1000);
    }
  };

  toRenderTitlePage = () => 'Edición de formulario';

  toRender() {
    const {
      classes, stateApp, dirty, handleSubmit, submitFailed,
      formErrors, dispatch, stateUser, initialValues, match: { params },
    } = this.props; // eslint-disable-line
    const { tabSelecIndex, anchorEl, typeFile, formatTop, csvLoading } = this.state;
    const tab0Error = formErrors && (
      formErrors.name || formErrors.url || formErrors.station
      || formErrors.restriction || formErrors.title
    );
    const tab1Error = formErrors && (
      formErrors.imgBgLg || formErrors.imgBgSm || formErrors.imgFormLg || formErrors.imgFormSm
      || formErrors.imgListLg || formErrors.imgListSm
    );
    const tab3Error = formErrors && (
      formErrors.imgBgLg || formErrors.imgBgSm
      || formErrors.imgListLg || formErrors.imgListSm || formErrors.imgLogoRadio || formErrors.imgLogo
      || formErrors.imgCompartir
    );

    const tab2Error = formErrors && formErrors.fields;
    const isNew = params.formId === 'new';
    return (
      <React.Fragment>
        {!isNew && (
          <form target="_blank" ref={this.formDown} method="post" action={`${window.globalParamsForm.api}form/list-registers`}>
            <input type='hidden' name="station" value={initialValues.getIn(['station', 'value']) || ''} />
            <input type='hidden' name="form" value={initialValues.get('id') || ''} />
            <input type='hidden' name="token" value={localStore.get('token') || ''} />
            <input type='hidden' name="type" value={typeFile} />
          </form>
        )}
        <Grid container alignItems="center" wrap="nowrap" justify="space-between"spacing={0} className={classes.hdRoot}>
          <Grid item className={classes.hdRootCol1}>
            <IconButton
              aria-label="Return"
              className={classes.backIcon}
              component={Link}
              to="/forms"
              edge="start"
              title="Volver a lista de formularios"
            >
              <ArrowBackIcon />
            </IconButton>
          </Grid>
          <Grid item className={classes.hdRootCol2}>
            <Tabs
              value={tabSelecIndex}
              indicatorColor="primary"
              textColor="primary"
              onChange={this.handleChangeTab}
            >
              <Tab label={
                (
                  <Badge
                    className={classes.margin}
                    color="secondary"
                    variant="dot"
                    invisible={!(submitFailed && tab0Error)}
                  >
                    {!formatTop ? 'Configuración' : 'Configuración top'}
                  </Badge>
                )}
              />
              <Tab label={
                (
                  <Badge
                    className={classes.margin}
                    color="secondary"
                    variant="dot"
                    invisible={!formatTop ?!(submitFailed && tab1Error):!(submitFailed && tab3Error)}
                  >
                    Diseño
                  </Badge>
                )}
              />
              <Tab style={!formatTop?{display:'none'}:{display:'block'}}  
              label={
                (
                  <Badge
                    className={classes.margin}
                    color="secondary"
                    variant="dot"
                    invisible={!(submitFailed && tab2Error)}
                  >
                    Preguntas
                  </Badge>
                )}
              />
              <Tab label={
                (
                  <Badge
                    className={classes.margin}
                    color="secondary"
                    variant="dot"
                    invisible={!(submitFailed && tab2Error)}
                  >
                    Campos
                  </Badge>
                )}
              />
              {!isNew && (
                <Tab label={
                  (
                    <div className={classes.downXls}>
                      <span>Registros</span>
                      <span
                        className={`svg ${csvLoading ? 'forma-spinner': ''}`}
                        role="button"
                        tabIndex={0}
                        onClick={this.handleDownload}
                      >
                        {!csvLoading && (<CloudDownloadIcon title="Descargar XLS" />)}
                      </span>
                      <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={this.handleCloseMenu}
                      >
                        { /*<MenuItem onClick={evt => this.handleCloseMenu(evt, 'xls')}>XLS</MenuItem>*/ }
                        <MenuItem disabled={csvLoading} onClick={evt => this.handleExportCsv(evt, initialValues.get('id'), false)}>CSV (Todos los participantes)</MenuItem>
                        <MenuItem disabled={csvLoading}>
                          <KeyboardDatePicker
                            label="CSV (Participantes del día)"
                            InputProps={{ readOnly: true }}
                            onClick={(evt) => this.setPickerStatus(evt)}
                            onClose={(evt) => this.setPickerStatus(evt)}
                            margin="normal"
                            format="dd/MM/yyyy"
                            views={['date']}
                            disableFuture={true}
                            autoOk
                            // value={selectedDate}
                            onChange={(date) => this.handleDateChange(date, initialValues.get('id'))}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                          />
                        </MenuItem>
                      </Menu>
                    </div>
                  )}
                />
              )}
              {!isNew && (
                <Tab label={
                  (
                    <div>
                      <span>Sorteo</span>
                    </div>
                  )}
                />
                  )}
            </Tabs>
          </Grid>
          <Grid item >
            <Button
              variant="contained"
              color="primary"
              disabled={!dirty}
              onClick={() => handleSubmit(this.handleSubmit)()}
            >      
              <SaveIcon className={classes.hdLeftIcon} />
              Guardar
            </Button>
          </Grid>
        </Grid>
        <Grid
          container
          justify="center"
          alignContent="flex-start"
          className={classes.bdWrap}
          id="bodyForm"
        >
          <Grid
            container
            className={clsx(classes.bdInner, { [classes.hidden]: tabSelecIndex !== 0 })}
            spacing={2}
          >
            <Fields
               names={[
                'name',
                'url',
                'station',
                'restriction',
                'expireAt',
                'conditionsFileUrl',
                'winnersFileUrl',
                'title',
                'description',
                'isPublic',
                'author',
                'cert',
                'formFormat',
                'thankText',
                'categoria',
                'nivel',
                'categoria_two',
                'nivel_two',
                'categoria_three',
                'nivel_three'
              ]}
              stateUser={stateUser}
              stateApp={stateApp}
              classes={classes}
              params ={params}
              stations={stateUser.get('stations')}
              component={EditConfig}
              handleShowMessage={this.handleShowMessage}
              tmpFiles={this.tmpFiles}
              nameForm={nameForm}
            />
          </Grid>
         {!formatTop ? (<Grid
            container
            className={clsx(classes.bdInner, { [classes.hidden]: tabSelecIndex !== 1 })}
            spacing={2}
          >
            <Fields
              names={[
                'imgBgLg',
                'imgBgSm',
                'imgFormLg',
                'imgFormSm',
                'imgListLg',
                'imgListSm',
              ]}
              classes={classes}
              component={EditDesign}
              handleShowMessage={this.handleShowMessage}
              tmpFiles={this.tmpFiles}
              nameForm={nameForm}
            />
          </Grid>):(<Grid
            container
            className={clsx(classes.bdInner, { [classes.hidden]: tabSelecIndex !== 1 })}
            spacing={2}
          >
            <Fields
              names={[
                'imgLogoRadio',
                'imgLogo',
                'imgCompartir',
                'imgBgLg',
                'imgBgSm',
                'imgListLg',
                'imgListSm',
                'colorEnfasis'
              ]}
              classes={classes}
              component={EditDesignTop}
              handleShowMessage={this.handleShowMessage}
              tmpFiles={this.tmpFiles}
              nameForm={nameForm}
            />
          </Grid>)}
          {formatTop && (<Grid
            container
            className={clsx(classes.bdInner, { [classes.hidden]: tabSelecIndex !== 2 })}
            spacing={2}
            ref={this.wrapCardsRef2}
          >
            <FieldArrayDnd
              name="fieldsTop"
              dispatch={dispatch}
              formid={params.formId}
              nameForm={nameForm}///correcto
              stateApp={stateApp}
              tmpFiles={this.tmpFiles}//correcto
              component={EditFieldsTop}
              handleShowMessage={this.handleShowMessage}//correcto
              wrapCardsRef={this.wrapCardsRef2}
            />
          </Grid>)}
          <Grid
            container
            className={clsx(classes.bdInner, { [classes.hidden]: tabSelecIndex !== 3 })}
            spacing={2}
            ref={this.wrapCardsRef}
          >
            <FieldArrayDnd
              name="fields"
              dispatch={dispatch}
              nameForm={nameForm}
              stateApp={stateApp}
              component={EditFields}
              handleShowMessage={this.handleShowMessage}
              wrapCardsRef={this.wrapCardsRef}
            />
          </Grid>
          {tabSelecIndex === 4 && (
            <Grid
              container
              className={clsx(
                classes.bdInnerTable,
                classes.listRegisters,
                { [classes.hidden]: tabSelecIndex !== 4 },
              )}
              spacing={2}
            >
              <ListRegisters
                station={initialValues.getIn(['station', 'value'])}
                form={initialValues.get('id')}
                fieldsTop={initialValues.get('fieldsTop')}
                fields={initialValues.get('fields')}
              />
            </Grid>
          )}
          {tabSelecIndex===5 && (
            <SorteoGanador 
                station={initialValues.getIn(['station', 'value'])}
                form={initialValues.get('id')}
                fieldsTop={initialValues.get('fieldsTop')}
                fields={initialValues.get('fields')}
            />
            )}
        </Grid>
      </React.Fragment>
    );
  }
}

export default binder({
  styles,
  stylesName: nameForm,
  reduxForm: {
    destroyOnUnmount: false,
    form: nameForm,
    validate: (values) => {
      let errors = {};
      if (typeof values === 'string') return errors;
      if (values.get('isPublic') && formatTopflag) {
        errors = validate.isEmpty('name,station,restriction,title,conditionsFileUrl,imgBgLg,imgBgSm,imgListLg,imgListSm,imgLogoRadio,imgLogo,imgCompartir', values, errors);
      } else if(values.get('isPublic') && !formatTopflag) {
        errors = validate.isEmpty('name,station,restriction,title,conditionsFileUrl,imgBgLg,imgBgSm,imgFormLg,imgFormSm,imgListLg,imgListSm', values, errors);
      }else {
        errors = validate.isEmpty('name,station,restriction,title', values, errors);
      }
      const fields = values.get('fields');
      if (!fields || fields.size === 0) {
        errors.fields = 'error';
      }

      return errors;
    },
  },
  states: {
    stateMain: ['forma', 'get'],
    stateSecond: ['forma', 'save'],
    stateApp: ['app', 'resources', 'data'],
    initialValues: ['forma', 'get', 'data'],
    formErrors: ['form', nameForm, 'syncErrors'],
    stateUser: ['user', 'login', 'data'],
  },
  actions: { getForma, saveForma },
  component: FormEdit,
});
