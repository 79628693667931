import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { Field } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CircularProgress from '@material-ui/core/CircularProgress';

import {
  Button, Typography, Grid,
} from '@material-ui/core';

import config from 'config.json';
import { REQUEST } from 'helpers/constants';
// import Logo from 'components/Icons/Logo';
import { loginUser } from 'reducers/user';
import TextField from 'components/Form/TextField';
import binder from 'helpers/binder';
import { setTitle } from 'helpers/utils';
import styles from './style';

const validate = (values) => {
  const errors = {};
  if (!values.get('username')) {
    errors.username = 'Ingrese nombre de usuario';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.get('username'))) {
    errors.username = 'Correo electrónico inválido';
  }
  if (!values.get('password')) {
    errors.password = 'Ingrese contraseña';
  }
  return errors;
};

class Login extends PureComponent {
  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    actions: PropTypes.objectOf(PropTypes.func).isRequired,
    handleSubmit: PropTypes.func.isRequired,
    stateMain: PropTypes.objectOf(PropTypes.any).isRequired,
  }

  static defaultProps = {}

  componentDidMount() {
    document.title = setTitle('Iniciar sesión // Forma // Producto Digital // GRPP');
  }

  // componentWillReceiveProps(nextProps) {
  //   const { stateMain } = this.props;
  //   const statusNew = nextProps.stateMain.getIn(['login', 'status']);
  //   if (statusNew !== stateMain.getIn(['login', 'status']) && statusNew === FAILURE) {
  //     this.setState({ snackbarMessage: true });
  //   }
  // }

  handleSubmit = (values) => {
    const { actions } = this.props;
    actions.loginUser(values.toJS());
  }

  // handleCloseSnackbar = () => {
  //   this.setState({ snackbarMessage: false });
  // }

  render() {
    const { classes, handleSubmit, stateMain } = this.props;
    // const { snackbarMessage } = this.state;
    const isWait = stateMain.getIn(['login', 'status']) === REQUEST;

    return (
      <Grid container alignItems="center" justify="center" spacing={0} style={{ height: '100%' }}>
        <Grid item xs={10} sm={6} className={classes.root}>
          <form onSubmit={handleSubmit(this.handleSubmit.bind(this))}>
            <Card className={classes.card} elevation={20}>
              <CardHeader
                avatar={<img src={config.logoRPP} alt={config.appName} title={config.appName} className={classes.logo} />}
                className={classes.cardHeader}
                title=""
                subheader="Iniciar sesión en Forma"
              />
              <CardContent>
                <Field
                  id="username"
                  label="Correo electrónico"
                  name="username"
                  type="email"
                  margin="normal"
                  placeholder="ej: qhapaq@pacha.club"
                  fullWidth
                  component={TextField}
                />
                <Field
                  id="password"
                  label="Contraseña"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  margin="normal"
                  fullWidth
                  component={TextField}
                />
              </CardContent>
              <CardActions className={classes.cardActions}>
                <div>
                  <Button type="submit" variant="contained" color="primary" disabled={isWait}>
                    {isWait && <CircularProgress size={20} className={classes.circularProgress} />}
                    Iniciar sesión
                  </Button>
                </div>
                <div className={classes.cardActionsMore}>
                  <Typography component="p">
                    <Link to="home">Recordar tu contraseña</Link>
                  </Typography>
                </div>
              </CardActions>
            </Card>
          </form>
        </Grid>
      </Grid>
    );
  }
}

export default binder({
  styles,
  stylesName: 'styleLogin',
  reduxForm: {
    form: 'formLogin',
    validate,
  },
  states: {
    stateMain: ['user'],
  },
  actions: { loginUser },
  component: Login,
});
