import React from 'react';
import { arrayMove } from 'redux-form/immutable';
import withStyles from '@material-ui/core/styles/withStyles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import clsx from 'clsx';
import { fromJS } from 'immutable';

import DragIcon from 'components/Icons/Drag';
import { slugify } from 'helpers/utils';
import EditFieldsCardTop from './EditFieldsCardTop';
import EditFieldsDrag from './EditFieldsDrag';
import styles from './EditFieldsStyles';

class EditFieldsTop extends React.PureComponent {
  cardHover = false;

  state = {
    indexActive: false,
  }

  handleAdd = () => {
    const { fields, stateApp } = this.props; // eslint-disable-line
    fields.push(fromJS({
      type: {
        label: 'Opcion Múltiple',
        value: 3,
        format: stateApp.getIn(['types', 'shortAnswerValueTypes', 0]),
      },
      question: 'Pregunta',
      answer: [],
    }));
    this.setState({
      indexActive: fields.length,
    });
    setTimeout(() => {
      const bodyForm = document.getElementById('bodyForm');
      const to = bodyForm.scrollHeight - bodyForm.offsetHeight;
      if (to > 0) {
        bodyForm.scrollTop = to;
      }
      const selected = document.querySelectorAll('.bodyFormItem input[type=text]');
      if (selected.length > 0) {
        selected[selected.length - 1].focus();
        selected[selected.length - 1].select();
      }
    }, 10);
  }

  handleClick = (event, index) => {
    const { indexActive } = this.state;
    const { fields } = this.props;
    if (fields.get(index).get('default')) {
      return;
    }
    if (indexActive !== index) {
      this.setState({ indexActive: index });
    }
  }

  handleMoveCard = (fromIndex, toIndex) => {
    const { wrapCardsRef, classes } = this.props; // eslint-disable-line
    if (this.cardHover) {
      this.cardHover.parentNode.removeChild(this.cardHover);
      this.cardHover = false;
    }
    const cards = wrapCardsRef.current.querySelectorAll('.card');
    const hoverChild = cards.item(toIndex);
    const newChild = document.createElement('div');
    newChild.className = classes.cardHover;
    this.cardHover = newChild;

    if (fromIndex > 0) {
      hoverChild.parentNode.insertBefore(newChild, hoverChild);
    } else {
      hoverChild.parentNode.insertBefore(newChild, hoverChild.nextSibling);
    }
  }

  handleMoveEndCard = (fromIndex, toIndex) => {
    const { dispatch, nameForm, fields } = this.props; // eslint-disable-line
    if (this.cardHover) {
      this.cardHover.parentNode.removeChild(this.cardHover);
      this.cardHover = false;
    }
    if (fromIndex === toIndex) return;
    this.setState({ indexActive: false });
    dispatch(arrayMove(nameForm, fields.name, fromIndex, toIndex));
  }

  render() {
    const { fields, stateApp, classes,dirt, tmpFiles, nameForm, formid } = this.props; // eslint-disable-line
    const { indexActive } = this.state;
    return (
      <>
        {fields.map((member, index) => (
          <EditFieldsDrag
            // id, moveCard, moveEndCard, children, propsCard, propsDrag
            key={index.toString()}
            id={index}
            moveCard={this.handleMoveCard}
            moveEndCard={this.handleMoveEndCard}
            onClickCard={this.handleClick}
            propsCard={{
              elevation: 0,
              className: clsx(
                'card',
                classes.card,
                { [classes.cardActive]: indexActive === index },
                { [classes.cardDefault]: fields.get(index).get('default') },
              ),
              key: slugify(member),
              onClick: event => this.handleClick(event, index),
            }}
            propsDrag={{
              className: classes.dragWrap,
              icon: <DragIcon />,
            }}
          >
            <EditFieldsCardTop
              index={index}
              fields={fields}
              indexActive={indexActive}
              member={member}
              stateApp={stateApp}
              classes={classes}
              formid={formid}
            />
          </EditFieldsDrag>
        ))}
        <Fab
          color="primary"
          aria-label="Add"
          className={classes.fab}
          onClick={this.handleAdd}
          title="Agregar pregunta"
        >
          <AddIcon />
        </Fab>
      </>
    );
  }
}

export default withStyles(styles)(EditFieldsTop);
