import { fromJS, Map } from 'immutable';
import { createAction, handleActions } from 'redux-actions';
import { put, takeLatest } from 'redux-saga/effects';
import { REQUEST, FAILURE, SUCCESS } from 'helpers/constants';
import request from 'helpers/request';

// Constants
export const types = {
  LIST_REQUEST: 'LOG/LIST_REQUEST',
  LIST_SUCCESS: 'LOG/LIST_SUCCESS',
  LIST_FAILURE: 'LOG/LIST_FAILURE',
};

// Actions
export const listLog = createAction(types.LIST_REQUEST);

const initialState = fromJS({
  list: {
    status: null,
    data: new Map(),
  },
});

// Reducer
export default handleActions(
  {
    [types.LIST_REQUEST]: state => state.setIn(['list', 'status'], REQUEST),
    [types.LIST_SUCCESS]: (state, action) => {
      // return state.merge({ list: { status: SUCCESS, data: action.data } });
      const newState = state.setIn(['list', 'status'], SUCCESS);
      return newState.setIn(['list', 'data'], fromJS(action.data));
    },
    [types.LIST_FAILURE]: state => state.setIn(['list', 'status'], FAILURE),
  },
  initialState,
);

// sagas
function* listLogSaga() {
  yield put({ type: types.LIST_SUCCESS, data: [] });
}

export const listLogAsync = props => request({
  api: 'log/list',
  method: 'POST',
  body: props,
});

export const logSagas = [
  takeLatest(types.LIST_REQUEST, listLogSaga),
];
