import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Field } from 'redux-form/immutable';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import RenderFileField from 'components/Form/FileField';
import clsx from 'clsx';

const Thumbnail = (props) => { // eslint-disable-line
  const {
    classes,
    handleShowMessage,
    fieldProps,
    tmpFiles,
    nameForm,
    meta: { touched, error },
  } = props;

  return (
    <Card
      elevation={0}
      className={clsx(classes.dsBox, { [`${classes.dsBox}Error`]: !!(touched && error) })}
    >
      <Field
        {...fieldProps}
        component={(field) => {
          const tmpFile = tmpFiles.get(fieldProps.name);
          if (tmpFile) {
            const reader = new FileReader();
           
            reader.onload = function (e) { // eslint-disable-line
              const div = document.getElementById(`${nameForm}-${fieldProps.name}-div`);
              div.style.backgroundImage = `url(${e.target.result})`;
            }.bind(this); // eslint-disable-line
            reader.readAsDataURL(tmpFile);
          }
          const dummy = Math.floor(Math.random() * 999);
          return (
            <div className={classes.dsImage}>
              <div id={`${nameForm}-${fieldProps.name}-div`} style={{ backgroundImage: field.input.value && !tmpFile ? `url(${field.input.value}?dummy=`+dummy+`)` : '' }} />
            </div>
          );
        }}
      />
      <CardActions>
        <Field
          {...fieldProps}
          onError={handleShowMessage}
          component={RenderFileField}
          tmpFiles={tmpFiles}
          nameForm={nameForm}
        />
      </CardActions>
    </Card>
  );
};

const EditDesign = ({
  classes, handleShowMessage, tmpFiles, nameForm, imgBgLg, imgBgSm, imgFormLg, imgFormSm, imgListLg, imgListSm,
}) => (
  <>
    <Grid item xs={6}>
      <Thumbnail
        classes={classes}
        handleShowMessage={handleShowMessage}
        tmpFiles={tmpFiles}
        nameForm={nameForm}
        meta={imgBgLg.meta}
        fieldProps={{
          label: 'Imagen de fondo(desktop)',
          name: 'imgBgLg',
          validFileTypes: ['.jpg', '.png'],
          tip: '3840x2160px',
        }}
      />
    </Grid>
    <Grid item xs={6}>
      <Thumbnail
        classes={classes}
        handleShowMessage={handleShowMessage}
        tmpFiles={tmpFiles}
        nameForm={nameForm}
        meta={imgBgSm.meta}
        fieldProps={{
          label: 'Imagen de fondo(mobile)',
          name: 'imgBgSm',
          validFileTypes: ['.jpg', '.png'],
          tip: '750x1334px',
        }}
      />
    </Grid>
    <Grid item xs={6}>
      <Thumbnail
        classes={classes}
        handleShowMessage={handleShowMessage}
        tmpFiles={tmpFiles}
        nameForm={nameForm}
        meta={imgFormLg.meta}
        fieldProps={{
          label: 'Imagen del formulario(desktop)',
          name: 'imgFormLg',
          validFileTypes: ['.jpg', '.png'],
          tip: '1160x1546px',
        }}
      />
    </Grid>
    <Grid item xs={6}>
      <Thumbnail
        classes={classes}
        handleShowMessage={handleShowMessage}
        tmpFiles={tmpFiles}
        nameForm={nameForm}
        meta={imgFormSm.meta}
        fieldProps={{
          label: 'Imagen del formulario(mobile)',
          name: 'imgFormSm',
          validFileTypes: ['.jpg', '.png'],
          tip: '710x710px',
        }}
      />
    </Grid>
    <Grid item xs={6}>
      <Thumbnail
        classes={classes}
        handleShowMessage={handleShowMessage}
        tmpFiles={tmpFiles}
        nameForm={nameForm}
        meta={imgListLg.meta}
        fieldProps={{
          label: 'Imagen de acceso directo al concurso(desktop)',
          name: 'imgListLg',
          validFileTypes: ['.jpg', '.png'],
          tip: '812x588px',
        }}
      />
    </Grid>
    <Grid item xs={6}>
      <Thumbnail
        classes={classes}
        handleShowMessage={handleShowMessage}
        tmpFiles={tmpFiles}
        nameForm={nameForm}
        meta={imgListSm.meta}
        fieldProps={{
          label: 'Imagen de acceso directo al concurso(mobile)',
          name: 'imgListSm',
          validFileTypes: ['.jpg', '.png'],
          tip: '708x258px',
        }}
      />
    </Grid>
  </>
);

export default EditDesign;
