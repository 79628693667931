import { fromJS, List } from 'immutable';
import { createAction, handleActions } from 'redux-actions';
import { put, takeLatest } from 'redux-saga/effects';

// Constants
export const types = {
  ADD: 'APP/NOTIFICATIONS/ADD',
  ADD_SUCCESS: 'APP/NOTIFICATIONS/ADD_SUCCESS',
  DELETE: 'APP/NOTIFICATIONS/DELETE',
  DELETE_SUCCESS: 'APP/NOTIFICATIONS/DELETE_SUCCESS',
};

// Actions
export const actionsNotification = {
  addNotification: createAction(types.ADD),
  deleteNotification: createAction(types.DELETE),
};

const initialState = List();

// Reducer
export default handleActions(
  {
    [types.ADD_SUCCESS]: (state, action) => state.push(fromJS(action.notification)),
    [types.DELETE_SUCCESS]: (state, action) => state.filter(item => item.get('id') !== action.id),
  },
  initialState,
);

// sagas
function* addNotificationSaga({ payload, notification }) {
  const item = payload || notification;
  const data = {
    id: new Date().getTime(),
    type: 'info',
    dismissAfter: 5000,
    ...item,
  };

  yield put({ type: types.ADD_SUCCESS, notification: data });
}

function* deleteNotificationSaga({ payload }) {
  yield put({ type: types.DELETE_SUCCESS, id: payload });
}

export const notificationSagas = [
  takeLatest(types.ADD, addNotificationSaga),
  takeLatest(types.DELETE, deleteNotificationSaga),
];
