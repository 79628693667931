import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';
import {
  put, takeLatest, call,
} from 'redux-saga/effects';
import { REQUEST, FAILURE, SUCCESS } from 'helpers/constants';

import request from 'helpers/request';
import { types as typesNotification } from 'reducers/notification';

// Constants
export const types = {
  LIST_REQUEST: 'FORMA/LIST_REQUEST',
  LIST_SUCCESS: 'FORMA/LIST_SUCCESS',
  LIST_FAILURE: 'FORMA/LIST_FAILURE',

  GET_REQUEST: 'FORMA/GET_REQUEST',
  GET_SUCCESS: 'FORMA/GET_SUCCESS',
  GET_FAILURE: 'FORMA/GET_FAILURE',
  GET_INITIAL: 'FORMA/GET_INITIAL',

  SAVE_REQUEST: 'FORMA/SAVE_REQUEST',
  SAVE_SUCCESS: 'FORMA/SAVE_SUCCESS',
  SAVE_FAILURE: 'FORMA/SAVE_FAILURE',

  DELETE_REQUEST: 'FORMA/DELETE_REQUEST',
  DELETE_SUCCESS: 'FORMA/DELETE_SUCCESS',
  DELETE_FAILURE: 'FORMA/DELETE_FAILURE',
};

// Actions
export const listForma = createAction(types.LIST_REQUEST);
export const getForma = createAction(types.GET_REQUEST);
// export const getFormaReset = createAction(types.GET_INITIAL);
export const saveForma = createAction(types.SAVE_REQUEST);
export const deleteForma = createAction(types.DELETE_REQUEST);

const initialState = fromJS({
  list: {
    status: null,
    data: null,
  },
  get: {
    status: null,
    data: null,
  },
  save: {
    status: null,
    data: null,
  },
  delete: {
    status: null,
    data: null,
  },
});

// Reducer
export default handleActions(
  {
    [types.LIST_SUCCESS]: (state, action) => {
      const newState = state.setIn(['list', 'status'], SUCCESS);
      return newState.setIn(['list', 'data'], fromJS(action.data));
    },
    [types.LIST_REQUEST]: state => state.setIn(['list', 'status'], REQUEST),
    [types.LIST_FAILURE]: (state, action) => {
      const newState = state.setIn(['list', 'status'], FAILURE);
      return newState.setIn(['list', 'data'], fromJS(action.data));
    },

    [types.GET_SUCCESS]: (state, action) => {
      const newState = state.setIn(['get', 'status'], SUCCESS);
      return newState.setIn(['get', 'data'], fromJS(action.data));
    },
    [types.GET_REQUEST]: state => state.setIn(['get', 'status'], REQUEST),
    [types.GET_FAILURE]: (state, action) => {
      const newState = state.setIn(['get', 'status'], FAILURE);
      return newState.setIn(['get', 'data'], fromJS(action.data));
    },
    [types.GET_INITIAL]: (state) => {
      const newState = state.setIn(['get', 'status'], null);
      return newState.setIn(['get', 'data'], fromJS(null));
    },

    [types.SAVE_SUCCESS]: (state, action) => {
      const newState = state.setIn(['save', 'status'], SUCCESS);
      return newState.setIn(['save', 'data'], fromJS(action.data));
    },
    [types.SAVE_REQUEST]: state => state.setIn(['save', 'status'], REQUEST),
    [types.SAVE_FAILURE]: (state, action) => {
      const newState = state.setIn(['save', 'status'], FAILURE);
      return newState.setIn(['save', 'data'], fromJS(action.data));
    },

    [types.DELETE_SUCCESS]: state => state.setIn(['delete', 'status'], SUCCESS),
    [types.DELETE_REQUEST]: state => state.setIn(['delete', 'status'], REQUEST),
    [types.DELETE_FAILURE]: state => state.setIn(['delete', 'status'], FAILURE),
  },
  initialState,
);

// sagas
function* listFormaSaga({ payload }) {
  const response = yield call(request, {
    api: 'form/list',
    method: 'POST',
    body: payload,
  });

  if (response.success) {
    yield put({ type: types.LIST_SUCCESS, data: response.body });
  } else {
    yield put({
      type: typesNotification.ADD,
      notification: {
        message: response.body,
        type: 'error',
      },
    });
    yield put({ type: types.LIST_FAILURE, data: response.body });
  }
}

function* getFormaSaga({ payload }) {
  if (payload && payload.initialize) {
    yield put({ type: types.GET_INITIAL });
    return;
  }
  const response = yield call(request, {
    api: `form/get/${payload}`,
    method: 'POST',
  });

  if (response.success) {
    yield put({ type: types.GET_SUCCESS, data: response.body });
  } else {
    yield put({
      type: typesNotification.ADD,
      notification: {
        message: response.body,
        type: 'error',
      },
    });
    yield put({ type: types.GET_FAILURE, data: response.body });
  }
}

function* saveFormaSaga(params) {
  const { data, files } = params.payload;

  // const dataJson = data;
  // dataJson.processType = 'data';

  const { fields } = data;
  const ids = 'a,b,c,d,e,f,g,h,i,j,k,l,m,n,o,p,q,r,s,t,u,v,w,x,y,z';

  fields.map((field) => {
    if (!field.id) {
      field.id = ids.split(',').find(text => !fields.find(fieldSearch => fieldSearch.id === text)); // eslint-disable-line
    }
    return field;
  });
  if (data.fieldsTop){
    const { fieldsTop } = data;
    const idst = 'fta,ftb,ftc,ftd,fte,ftf,ftg,fth,fti,ftj,ftk,ftl,ftm,ftn,fto,ftp,ftq,ftr,fts,ftt,ftu,ftv,ftw,ftx,fty,ftz';
    fieldsTop.map((field) => {  
      if (!field.id) {
        field.id = idst.split(',').find(text => !fieldsTop.find(fieldSearch => fieldSearch.id === text)); // eslint-disable-line
      }
      return field;
    });
  }


  // establecemos expiracion a final de dia.
  if (data.expireAt) {
    const expireAt = new Date(data.expireAt * 1000);
    expireAt.setHours(23, 59, 59, 0);
    data.expireAt = expireAt / 1000;
  }

  let response = yield call(request, {
    api: 'form/save',
    method: 'POST',
    body: data,
  });
  if (response.success) {
    // enviamos archivos
    if (files) {
      const keysName = Object.keys(files);
      if (keysName.length > 0) {
        const formData = new FormData();
        formData.append('_id', response.body._id); // eslint-disable-line
        let total = 0;
        keysName.forEach((name) => {
          total += 1;
          formData.append(name, files[name]);
        });
        if (total > 0) {
          response = yield call(request, {
            api: 'form/save',
            method: 'POST',
            formData,
          });
          if (!response.success) {
            yield put({
              type: typesNotification.ADD,
              notification: {
                message: 'Ocurrió un error al guardar los archivos',
                type: 'error',
              },
            });
          } else {
            yield put({
              type: typesNotification.ADD,
              notification: {
                message: 'Datos guardados',
                type: 'success',
              },
            });
          }
        } else {
          yield put({
            type: typesNotification.ADD,
            notification: {
              message: 'Datos guardados',
              type: 'success',
            },
          });
        }
      } else {
        yield put({
          type: typesNotification.ADD,
          notification: {
            message: 'Datos guardados',
            type: 'success',
          },
        });
      }
    }
    yield put({ type: types.SAVE_SUCCESS, data: response.body });
    yield put({ type: types.GET_SUCCESS, data: response.body });
  } else {
    yield put({
      type: typesNotification.ADD,
      notification: {
        message: response.body,
        type: 'error',
      },
    });
    yield put({ type: types.SAVE_FAILURE });
  }
}

// const formaListState = state => state.getIn(['forma']);

function* deleteFormaSaga({ payload }) {
  // const listState = yield select(formaListState);

  const response = yield call(request, {
    api: `form/delete/${payload.id}`,
    method: 'POST',
  });

  if (response.success) {
    yield put({ type: types.DELETE_SUCCESS });
    yield put({
      type: typesNotification.ADD,
      notification: {
        message: 'Se ha eliminado el formulario',
        type: 'success',
      },
    });
  } else {
    yield put({
      type: typesNotification.ADD,
      notification: {
        message: response.body,
        type: 'error',
      },
    });
    yield put({ type: types.DELETE_FAILURE });
  }
}

export const deleteFormaAsync = id => request({
  api: `form/delete/${id}`,
  method: 'POST',
});

export const listFormaAsync = props => request({
  api: 'form/list',
  method: 'POST',
  body: props,
});

export const exportCSVAsync = props => request({
  api: `report/get-form-report?idForm=${props.idForm}&today=${props.today}`,
  method: 'POST',
  body: "",
});

export const formaSagas = [
  takeLatest(types.LIST_REQUEST, listFormaSaga),
  takeLatest(types.GET_REQUEST, getFormaSaga),
  takeLatest(types.SAVE_REQUEST, saveFormaSaga),
  takeLatest(types.DELETE_REQUEST, deleteFormaSaga),
];
