import React from 'react';
import { Route, Switch /* , Redirect */ } from 'react-router-dom';

import App from 'containers/App/index';
import Login from 'containers/Login';

const Routes = () => (
  <Switch>
    <Route exact path="/login" component={Login} />
    <Route path="/" component={App} />
  </Switch>
);

export default Routes;
