/* eslint-disable react/prop-types */
import React from 'react';
import getUnixTime from 'date-fns/getUnixTime';
import fromUnixTime from 'date-fns/fromUnixTime';

import { KeyboardDatePicker } from '@material-ui/pickers';

const RenderDateField = ({
  input, meta: { touched, error }, helperText, ...custom
}) => {
  const helperTextLocal = typeof helperText === 'function' ? helperText(input) : helperText;
  const value = typeof input.value === 'number' ? fromUnixTime(input.value) : null;
  return (
    <KeyboardDatePicker
      margin="normal"
      error={!!touched && !!error}
      name={input.name}
      // label={custom.label}
      // placeholder="dd/mm/yyyy"
      views={['year', 'month', 'date']}
      value={value}
      autoOk
      onChange={date => input.onChange(getUnixTime(date))}
      format="dd/MM/yyyy"
      helperText={helperTextLocal}
      {...custom}
    />
  );
};

export default RenderDateField;
