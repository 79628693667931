import config from 'config.json';
import format from 'date-fns/format';
import fromUnixTime from 'date-fns/fromUnixTime';

export const setTitle = (title = 'App') => `${title} - ${config.appName}`;

/* eslint-disable */
export const resizeDelay = {
  delay: 250,
  on: (component, autoRun) => {
    component._handleScreenResize = () => {
      if (typeof component._resizeTimeout !== 'undefined') {
        window.clearTimeout(component._resizeTimeout);
        delete component._resizeTimeout;
      }
      component._resizeTimeout = window.setTimeout(() => {
        if (typeof component.onScreenResize === 'function') {
          component.onScreenResize();
        }
      }, resizeDelay.delay);
    };
    if (autoRun) component.onScreenResize();
    window.addEventListener('resize', component._handleScreenResize);
  },
  off: (component) => {
    window.removeEventListener('resize', component._handleScreenResize);
  },
};
/* eslint-enable */

export const slugify = (strIn) => {
  // http://stackoverflow.com/a/5782563/6849374

  let str = typeof strIn === 'undefined' ? '' : String(strIn);
  str = str.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  const from = 'ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;';
  const to = 'aaaaaeeeeeiiiiooooouuuunc------';
  for (let i = 0, l = from.length; i < l; i += 1) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-'); // collapse dashes
  return str;
};

export const tmpFiles = (groupName, reset = false) => {
  window.tmpFiles = window.tmpFiles || {};
  if (reset) {
    window.tmpFiles[groupName] = {};
  } else {
    window.tmpFiles[groupName] = window.tmpFiles[groupName] || {};
  }
  return {
    save: (fieldName, file) => {
      // window.tmpFiles[groupName][slugify(fieldName)] = file;
      window.tmpFiles[groupName][fieldName] = file;
    },
    get: fieldName => window.tmpFiles[groupName][fieldName],
    delete: (fieldName) => {
      delete window.tmpFiles[groupName][fieldName];
    },
    destroy: () => delete window.tmpFiles[groupName],
  };
};

export const UCFirst = str => str.charAt(0).toUpperCase() + str.toLowerCase().slice(1);
export const formatFromUnix = (str, formatStr) => {
  if (str) {
    return format(fromUnixTime(str), formatStr || 'dd/MM/yyyy');
  }
  return '';
};

export const validate = {
  isEmpty: (names, values, errors) => {
    const error = [];
    names.split(',').map((name) => {
      let value = values.get(name);
      if (typeof value === 'string') value = value.trim();
      else if (Number.isFinite(value)) value = value.toString().trim();
      else if (Array.isArray(value)) value = value.length > 0;
      if (!value) {
        error[name] = 'error';
      }
      return name;
    });
    return { ...errors, ...error };
  },
};

export default {};
