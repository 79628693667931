import config from 'config.json';

const localStore = {
  get: (name) => {
    const local = JSON.parse(localStorage.getItem(config.name) || '{}');
    return local[name];
  },
  set: (name, value) => {
    const local = JSON.parse(localStorage.getItem(config.name) || '{}');
    local[name] = value;
    localStorage.setItem(config.name, JSON.stringify(local || ''));
  },
  delete: (name) => {
    const local = JSON.parse(localStorage.getItem(config.name) || '{}');
    delete local[name];
    localStorage.setItem(config.name, JSON.stringify(local || ''));
  },
};
export default localStore;
