import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import withStyles from '@material-ui/core/styles/withStyles';
import Immutable from 'immutable';
import Notification from './Notification';

const styles = theme => ({
  container: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 360,
    },
    position: 'fixed',
    bottom: 0,
    right: 0,
    padding: theme.spacing(2),
    zIndex: 1501,
  },
  animaEnter: {
    opacity: 0,
    transform: 'translateX(110%)',
    transition: 'all 400ms ease',
    animationFillMode: 'forwards',
  },
  animaEnterActive: {
    opacity: 1,
    transform: 'translateX(0)',
  },
  animaExit: {
    opacity: 1,
    transform: 'translateX(0)',
    transition: 'all 400ms ease',
    animationFillMode: 'forwards',
  },
  animaExitActive: {
    opacity: 0,
    transform: 'translateX(110%)',
  },
});

class Notifications extends PureComponent {
  // static defaultProps = {
  //   stateNotifications: Immutable.Map(),
  // }

  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    stateNotifications: PropTypes.instanceOf(Immutable.List).isRequired,
  }

  render() {
    const { classes, stateNotifications } = this.props;

    return (
      <TransitionGroup className={classes.container}>
        {stateNotifications.map((item, index) => (
          <CSSTransition
            key={index.toString()}
            timeout={500}
            classNames={{
              enter: classes.animaEnter,
              enterActive: classes.animaEnterActive,
              exit: classes.animaExit,
              exitActive: classes.animaExitActive,
            }}
          >
            <Notification key={index.toString()} notification={item} />
          </CSSTransition>
        ))}
      </TransitionGroup>
    );
  }
}

const mapStateToProps = state => ({
  stateNotifications: state.get('notification'),
});

export default connect(
  mapStateToProps,
  null,
)(withStyles(styles)(Notifications));
