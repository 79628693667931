import { createStore, compose, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { /* connectRouter, */ routerMiddleware } from 'connected-react-router/immutable';
import { fromJS } from 'immutable';
import rootSagas, { rootReducers } from './reducers';

const sagaMiddleware = createSagaMiddleware();

export default function configureStore(initialState = {}, history) {
  const middlewares = [sagaMiddleware, routerMiddleware(history)];
  const enhancers = [applyMiddleware(...middlewares)];
  let store;

  if (process.env.NODE_ENV === 'production') {
    store = createStore(
      // connectRouter(history)(rootReducers),
      rootReducers(history),
      fromJS(initialState),
      compose(...enhancers),
    );
  } else {
    const composeEnhancers = typeof window === 'object'
      && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? // eslint-disable-line
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose; // eslint-disable-line

    store = createStore(
      // connectRouter(history)(rootReducers),
      rootReducers(history),
      fromJS(initialState),
      composeEnhancers(...enhancers),
    );

    if (module.hot) {
      /*
      module.hot.accept('./reducers', () => {
        store.replaceReducer(connectRouter(history)(rootReducers))
      })
      */

      module.hot.accept('./reducers', () => {
        import('./reducers').then((reducerModule) => {
          const createReducers = reducerModule.default;
          const nextReducers = createReducers(store.asyncReducers);

          store.replaceReducer(nextReducers);
        });
      });
    }
  }

  sagaMiddleware.run(rootSagas);
  store.asyncReducers = {};
  return store;
}
