import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import clsx from 'clsx';

const styles = theme => ({
  default: {
    width: '100%',
    margin: `${theme.spacing(2)}px 0 ${theme.spacing(1)}px`,
    '& > label': {
      transform: 'translate(0, 1.5px) scale(0.75)',
      transformOrigin: 'top left',
    },
    '& > h6': {
      marginTop: `${theme.spacing(2)}px`,
      marginBottom: 0,
      lineHeight: `${theme.spacing(4)}px`,
      height: `${theme.spacing(4)}px`,
      display: 'flex',
      '& > div': {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        position: 'relative',
        width: '100%',
        marginLeft: theme.spacing(7),
        height: theme.spacing(6),
        lineHeight: `${theme.spacing(6)}px`,
      },
    },
  },
  nomargin: {
    margin: 0,
  },
  iconButton: {
    marginRight: theme.spacing(0.5),
    cursor: 'pointer',
    boxShadow: `inset 0px 0px 0px 1px ${theme.app.colorLine}`,
    position: 'absolute',
    zIndex: 0,
    '&:hover': {
      boxShadow: 'none',
    },
  },
  textEmpty: {
    color: 'rgba(0, 0, 0, 0.54)', // TODO
  },
  tip: {
    color: 'rgba(0, 0, 0, 0.54)', // TODO
    fontSize: '12px',
    position: 'absolute',
    left: 0,
    top: 16,
  },
  inputFile: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    opacity: 0,
    zIndex: 1,
    cursor: 'pointer',
  },
  error: {
    '& label': {
      color: '#f44336',
    },
    '& button + span' : {
      color: '#f44336',
    },
  },
});

const RenderFileField = (props) => {
  const {
    input,
    margin,
    label,
    classes,
    validFileTypes,
    validFileSizeMax = 1,
    onError,
    contentIsEmpty = 'Ningún archivo seleccionado',
    tmpFiles,
    nameForm,
    tip,
    meta: { touched, error },
  } = props;
  const { value } = input;
  const labelTip = `(${validFileTypes} ${validFileSizeMax}MB) ${tip || ''}`;
  const onChange = (event) => {
    if (event.target.files.length < 1) return false;
    const fileInput = event.target;
    const fileName = fileInput.value.replace(/^.*[\\\\/]/, '');
    const types = validFileTypes.join('|').replace(/\./g, '\\.');
    const file = event.target.files[0];
    const fileSizeMb = Math.round((file.size / 1024 / 1024) * 100) / 100; // mb
    
    if (validFileTypes) {
      const okType = (new RegExp(`(${types})$`, 'i')).test(fileName);
      const okSize = fileSizeMb <= validFileSizeMax;
      if (okType && okSize) {
        tmpFiles.save(input.name, event.target.files[0]);
        input.onChange(fileName);
        return true;
      }

      if (onError) {
        onError({
          title: 'Archivo inválido',
          message: `Seleccione un tipo de archivo válido ${labelTip}`,
        });
      }
      return false;
    }
    return true;
  };

  let valueTag;
  if (value && value.indexOf('http') === 0) {
    const nameFile = value.split('/');
    valueTag = (<a target="_blank" rel="noopener noreferrer" href={value}>{nameFile[nameFile.length - 1]}</a>);
  } else if (value) {
    valueTag = value;
  } else {
    valueTag = contentIsEmpty;
  }

  return (
    <FormControl
      className={clsx(
        classes.default,
        { [classes.nomargin]: margin === 'none' },
        { [classes.error]: !!(touched && error) },
      )}
    >
      <InputLabel>{label}</InputLabel>

      <Typography variant="subtitle1" gutterBottom>
        <IconButton aria-label="File" size="medium" className={classes.iconButton} color="primary">
          <input type="file" id={`${nameForm}-${input.name}`} className={`fileForm ${classes.inputFile}`} onChange={onChange} title="Seleccionar archivo" />
          <AttachFileIcon fontSize="inherit" />
        </IconButton>
        <div>
          <span className={!value ? classes.textEmpty : ''}>{valueTag}</span>
          <span className={classes.tip}>{labelTip}</span>
        </div>
      </Typography>
    </FormControl>
  );
};

export default withStyles(styles)(RenderFileField);
