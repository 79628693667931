import React from 'react';
import { Field } from 'redux-form/immutable';
import Grid from '@material-ui/core/Grid';
import { fromJS } from 'immutable';
// importamos los componentes para el Thumbnail
import imgDefault from 'static/avatar/default.png'
import withStyles from '@material-ui/core/styles/withStyles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import RenderTextField from 'components/Form/TextField';
import { slugify } from 'helpers/utils';
import { OTROS } from 'helpers/constants';
import OptionImage from 'components/Form/OptionImage'

const stylehidden ={
  hidden: {
  display:'none'
  },
  spacing: {
    paddingBottom:'5px'
  }
}
const styles = theme => ({
  renderTextField: props => ({
    flex: 'auto',
    width: 'auto',
    marginTop: theme.spacing(1),
    lineHeight: `${theme.spacing(4)}px`,
    '& > div:before': {
      borderBottom: props.readOnly ? 'none' : '1px dotted rgba(0, 0, 0, 0.2)',
    },
    '& input': {
      padding: '4px 0 5px',
    },
  }),
  lblAdd: {
    '&:hover': {
      borderBottom: '1px dotted rgba(0, 0, 0, 0.8)',
    },
  },
  lblOtros: {
    color: theme.palette.primary[500],
    cursor: 'pointer',
    '&:hover': {
      borderBottom: `1px solid ${theme.palette.primary[500]}`,
    },
  },
  icon: {
    '& > p': {
      // lineHeight: `${theme.spacing(6)}px`,
      padding: theme.spacing(1.5),
    },
  },
});

class FieldTypeOptionsTop extends React.PureComponent {
  lastRef = React.createRef();
  handleAdd = (event) => {
    const { fields } = this.props; // eslint-disable-line
    const id = fields.length;
    const newItem = fromJS({
      label: event.target.innerText.indexOf('"OTROS"') >= 0 ? OTROS : `Opción ${id + 1}`,
      value: (new Date()).getTime(),
      imgTop: "",
    });
      fields.push(newItem);
    setTimeout(() => {
      // this.lastRef
      const selected = this.lastRef.current.parentNode.querySelectorAll('[type=text]');
      if (selected.length > 0) {
        selected[selected.length - 1].focus();
        selected[selected.length - 1].select();
      }
    }, 100);
  }
componentDidUpdate=()=>{
    const { fields } = this.props
      fields.map((member, index) =>{
        const avName=String(member+'-img');
        if (fields.get(index).get("imgTop")!=='') {
          document.getElementById(avName).src=fields.get(index).get("imgTop");
        }else{
          document.getElementById(avName).src=imgDefault;
        }
        return true;
      })    
    }
  render() {
    const { fields, classes, renderIcon, readOnly, formid } = this.props; // eslint-disable-line
    const getAll = fields.getAll();
    if (!getAll) return null;
    return (
      <>
        {fields.map((member, index) => {
          return (
            <Grid
            style={stylehidden.spacing}
              key={slugify(`${member}.label`)}
              container
              justify='space-between'
            >
              <div className={classes.icon}>
                {renderIcon(index + 1)}
              </div>
              <OptionImage
                member={member}
                formid={formid}
                fields={fields}
                index ={index}
              />
              <Grid item xs={3}>
              <Field
                  placeholder="Título"
                  fullWidth
                  margin="normal"
                  name={`${member}.label`}
                  className={classes.renderTextField}
                  component={RenderTextField}
                  
                />
              </Grid>
              <Grid style={stylehidden.hidden}  item xs={3}>
              <Field
                  placeholder="ImageTop"
                  margin="normal"
                  name={`${member}.imgTop`}
                  className={classes.renderTextField}
                  component={RenderTextField}
                />
              </Grid>
         
              <Field
                  fullWidth
                  placeholder="Descripcion Corta"
                  margin="normal"
                  name={`${member}.description`}
                  className={classes.renderTextField}
                  component={RenderTextField}
                />
          
              {!readOnly && (
                <IconButton style={{height:'48px'}} onClick={() => fields.remove(index)}>
                  <CloseIcon />
                </IconButton>
              )}
            </Grid>
          );
        })}
        {!readOnly && (
          <Grid
            key="add-new-type-opt3"
            container
            justify="space-between"
            ref={this.lastRef}
          >
            <div className={classes.renderTextField}>
              <Typography
                component="span"
                className={classes.lblAdd}
                onClick={this.handleAdd}
              >
                Agregar una opción
              </Typography>
            </div>
          </Grid>
        )}
      </>
    );
  }
}

export default withStyles(styles)(FieldTypeOptionsTop);
