const styleSheet = theme => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: theme.spacing(42),
      flexBasis: theme.spacing(42),
    },
  },
  wrap: {
    flexGrow: 1,
  },
  logo: {
    width: '80px',
    // height: '42px',
  },
  card: {
    // boxShadow: '0px 0px 20px 0px rgba(233,234,239,1)!important',
    padding: theme.spacing(1),
  },
  cardHeader: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingBottom: 0,
    '& span': {
      '&:first-of-type': {
        fontSize: '20px',
      },
    },
  },
  cardActions: {
    flexDirection: 'column',
    alignItems: 'flex-start',

  },
  circularProgress: {
    marginRight: theme.spacing(1),
  },
  cardActionsMore: {
    display: 'none',
    marginTop: theme.spacing(2),
    '& p': {
      paddingLeft: theme.spacing(2),
      position: 'relative',
      '&:before': {
        content: "''",
        display: 'inline-block',
        height: theme.spacing(1),
        width: theme.spacing(1),
        position: 'absolute',
        left: 0,
        top: '5px',
        borderRadius: '50%',
        backgroundColor: '#e2e2e4',
        marginRight: theme.spacing(1),
      },
    },
  },
});

export default styleSheet;
