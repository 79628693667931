import React from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';
import { listFormaXSorteoAsync } from 'reducers/formax';
import Button from '@material-ui/core/Button';
import CasinoRoundedIcon from '@material-ui/icons/CasinoRounded';
import { createMuiTheme } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
const theme = createMuiTheme({
  palette: {
    primary: green,
  },
});

const styles ={
  root: {
    width: '100%',
    marginLeft: '25%',
    marginRight: '25%',
    marginTop:'10px',
  },
  card:{
    boxShadow: '3px 4px 7px 3px rgba(196,196,196,1)',
  },
  btn:{
    // display:'inline table',
    // maxWidth:theme.spacing(50),
    color: theme.palette.getContrastText(green[700]),
    backgroundColor: green[600],
    '&:hover': {
      backgroundColor: green[700],
    },
    // marginRight:'10px',
    // marginLeft:'10px',
    marginBottom:'10px',
  }
}
export default class SorteoGanador extends React.Component {
     
      constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
      }
      state = {
        data: [],
        loading: false,
        loaded:false,
      }

  handleClick(){
    const { station, form , fields, fieldsTop} = this.props;
    const paramsFetchData ={station, form};
    const columns = fields
    .map(field => ({ accessor: field.get('id'), Header: field.get('question') }))
    .toJS();
    if (fieldsTop) {
      const columnsTop = fieldsTop
      .map(field => ({ accessor: field.get('id'), Header: field.get('question') }))
      .toJS();
      for (let index = 0; index < columnsTop.length; index++) {
        columns.push(columnsTop[index]);
      }
    }
    this.setState({loading: true});
    listFormaXSorteoAsync({...paramsFetchData,}).then((result)=>{
   const { success, body:{rows}} = result;
   if (success) {
     this.setState({
       loaded:true,
       data:(rows || []),
       keys:(columns|| []),
     })
   }else{
    this.setState({
      loading: false,
      data: [],
      keys:[],
    });
   }
 })
}

render(){
    const { data, loaded, keys } = this.state
    if(!loaded){
      return (
        <div style={styles.root}>
          <div>
          <Button variant="contained" title='Sortear' startIcon={<CasinoRoundedIcon />} style={styles.btn} onClick={this.handleClick} color="primary">Sortear</Button>
          </div>
          <Alert style={styles.card} severity="success">
            <AlertTitle>No hay Ganador</AlertTitle>
            haga clic en Sortear para Obtener un Ganador
          </Alert>
        </div>
      );
    }else{
      return (
        <div style={styles.root}>
          <div>
         <Button variant="contained" title='Nuevo Sorteo'  startIcon={<CasinoRoundedIcon />} style={styles.btn} onClick={this.handleClick} color="primary">Nuevo Sorteo</Button>
          </div>
          <Alert style={styles.card} severity="success">
            <AlertTitle>Ganador</AlertTitle>
            <ul>
              {keys.map((campo, index) =>{ 
              
                  return (
                    <li key={index}> 
                    <p><strong>{campo.Header}: </strong>{data.map(item =>{ return item[campo.accessor]})}</p>
                    </li>    
                    );
                    
                  })}
            </ul>
          </Alert>
        </div>
      );
    }
}
}
