  import React from 'react';
  import Grid from '@material-ui/core/Grid';
  import { Field } from 'redux-form/immutable';
  import Card from '@material-ui/core/Card';
  import CardActions from '@material-ui/core/CardActions';
  import RenderFileField from 'components/Form/FileField';
  import ColorPickerField from 'components/Form/ColorPickerField';
  import Typography from '@material-ui/core/Typography';
  import clsx from 'clsx';

  const Thumbnail = (props) => { // eslint-disable-line
    const {
      classes,
      handleShowMessage,
      fieldProps,
      tmpFiles,
      nameForm,
      meta: { touched, error },
    } = props
    return (
      <Card
        elevation={0}
        className={clsx(classes.dsBox, { [`${classes.dsBox}Error`]: !!(touched && error) })}
      >
        <Field
          {...fieldProps}
          component={(field) => {
            const tmpFile = tmpFiles.get(fieldProps.name);
            if (tmpFile) {
              const reader = new FileReader();
              reader.onload = function (e) { // eslint-disable-line
                const div = document.getElementById(`${nameForm}-${fieldProps.name}-div`);
                div.style.backgroundImage = `url(${e.target.result})`;
              }.bind(this); // eslint-disable-line
              reader.readAsDataURL(tmpFile);
            }
            const dummy = Math.floor(Math.random() * 999);
            return (
              <div className={classes.dsImage}>
                <div id={`${nameForm}-${fieldProps.name}-div`} style={{ backgroundImage: field.input.value && !tmpFile ? `url(${field.input.value}?dummy=`+dummy+`)` : '' }} />
              </div>
            );
          }}
        />
        <CardActions>
          <Field
            {...fieldProps}
            onError={handleShowMessage}
            component={RenderFileField}
            tmpFiles={tmpFiles}
            nameForm={nameForm}
          />
        </CardActions>
      </Card>
    );
  };

  const EditDesignTop = ({
    classes, handleShowMessage, tmpFiles, nameForm, imgLogo, imgLogoRadio, imgCompartir, imgBgLg, imgBgSm, imgListLg, imgListSm
  }) => (
    <>
    <Grid item xs={12}>
    <Typography>
        Imágenes Formulario
    </Typography>
    </Grid>
      <Grid item xs={4}>
        <Thumbnail
          classes={classes}
          handleShowMessage={handleShowMessage}
          tmpFiles={tmpFiles}
          nameForm={nameForm}
          meta={imgLogoRadio.meta}
          fieldProps={{
            label: 'Imagen Logo Radio',
            name: 'imgLogoRadio',
            validFileTypes: ['.jpg', '.png','.svg'],
            tip: '184x87px',
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <Thumbnail
          classes={classes}
          handleShowMessage={handleShowMessage}
          tmpFiles={tmpFiles}
          nameForm={nameForm}
          meta={imgLogo.meta}
          fieldProps={{
            label: 'Imagen Logo Concurso',
            name: 'imgLogo',
            validFileTypes: ['.jpg', '.png','.svg'],
            tip: '233x110px',
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <Thumbnail
          classes={classes}
          handleShowMessage={handleShowMessage}
          tmpFiles={tmpFiles}
          nameForm={nameForm}
          meta={imgCompartir.meta}
          fieldProps={{
            label: 'Imagen Compartir',
            name: 'imgCompartir',
            validFileTypes: ['.jpg', '.png'],
            tip: '1200x630px',
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <Thumbnail
          classes={classes}
          handleShowMessage={handleShowMessage}
          tmpFiles={tmpFiles}
          nameForm={nameForm}
          meta={imgBgLg.meta}
          fieldProps={{
            label: 'Imagen de fondo(desktop)',
            name: 'imgBgLg',
            validFileTypes: ['.jpg', '.png'],
            tip: '1920x1080px',
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <Thumbnail
          classes={classes}
          handleShowMessage={handleShowMessage}
          tmpFiles={tmpFiles}
          nameForm={nameForm}
          meta={imgBgSm.meta}
          fieldProps={{
            label: 'Imagen de fondo(mobile)',
            name: 'imgBgSm',
            validFileTypes: ['.jpg', '.png'],
            tip: '375x667px',
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <Thumbnail
          classes={classes}
          handleShowMessage={handleShowMessage}
          tmpFiles={tmpFiles}
          nameForm={nameForm}
          meta={imgListLg.meta}
          fieldProps={{
            label: 'Imagen de acceso directo al concurso(desktop)',
            name: 'imgListLg',
            validFileTypes: ['.jpg', '.png'],
            tip: '812x588px',
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <Thumbnail
          classes={classes}
          handleShowMessage={handleShowMessage}
          tmpFiles={tmpFiles}
          nameForm={nameForm}
          meta={imgListSm.meta}
          fieldProps={{
            label: 'Imagen de acceso directo al concurso(mobile)',
            name: 'imgListSm',
            validFileTypes: ['.jpg', '.png'],
            tip: '708x258px',
          }}
        />
      </Grid>
      <Grid item xs={4}>
      
      <div style={{marginBottom : '1em'}}>
      <Typography>
        Color de Énfasis
      </Typography>
      </div>
    

      <Field
          label="Color Enfasis"
          name="colorEnfasis"
          component={ColorPickerField}
      />
      </Grid>
    </>
  );

  export default EditDesignTop;
