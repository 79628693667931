import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import shadows from '@material-ui/core/styles/shadows';
import {
  fade, lighten, rgbToHex, hexToRgb, darken,
} from '@material-ui/core/styles/colorManipulator';

const colorDark = '#091e43'; // '#505070'; TODO si cambia esto, buscar y reemplazar en otros modulos
const colorDarkRGB = hexToRgb(colorDark);

const colorBackground = rgbToHex(lighten(colorDark, 0.9655)); // = '#f8f8fa';
const colorHoverItem = fade(colorDark, 0.08);
const unit = 8;
// '0px 0px 20px 0px rgba(233,234,239,1)!important'

// const shadowsNew = shadows.map((shadow, index) => `2px 2px ${index + 1}px 0px rgba(${hexToRgb(colorDark).replace(/[rgb()]*/ig, '')},.3)`);

// (59.2 - (2.2 * x))/19 formula

// const shadowsNew = shadows.map((shadow, index) => `0px 0px ${index + 1}px 0px rgba(${hexToRgb(colorDark).replace(/[rgb()]*/ig, '')},${(59.2 - (2.2 * index)) / 19})`);
const shadowsNew = shadows.map((shadow, index) => `0px 0px ${index + 1}px 0px rgba(${hexToRgb(colorDark).replace(/[rgb()]*/ig, '')},0.3)`);
const colorLine = fade(colorDark, 0.1);

const theme = createMuiTheme({
  palette: {
    background: {
      default: colorBackground,
    },
    primary: {
      50: '#e0ecf5',
      100: '#b3cfe7',
      200: '#80b0d7',
      300: '#4d90c7',
      400: '#2678bb',
      500: '#0060af',
      600: '#0058a8',
      700: '#004e9f',
      800: '#004496',
      900: '#003386',
      A100: '#b3c9ff',
      A200: '#80a6ff',
      A400: '#4d82ff',
      A700: '#3370ff',
      contrastDefaultColor: 'light',
    },
  },
  overrides: {
    MuiButton: {
      label: {
        lineHeight: `${unit * 3}px`,
      },
      contained: {
        boxShadow: 'none',
      },
    },
    MuiListItem: {
      root: {
        paddingTop: unit,
        paddingBottom: unit,
      },
      button: {
        '&:hover': {
          backgroundColor: colorHoverItem,
        },
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: fade(colorDark, 0.3),
      },
    },
    MuiTab: {
      textColorPrimary: {
        minWidth: 'auto',
        '&$selected': {
          fontWeight: 'bold',
        },
      },
    },
    MuiInput: {
      underline: {
        '&:before': {
          borderBottom: '1px dotted rgba(0, 0, 0, 0.4)',
        },
        '&:hover:not($disabled):before': {
          borderBottom: '1px dotted rgba(0, 0, 0, 1)',
        },
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: 0,
        boxShadow: `0px 1px 0px 0px ${colorLine}`,
      },
      head: {
        fontWeight: 'bold',
      },
    },
    /*
    MuiTableRow: {
      root: {
        '&$selected': {
          backgroundColor: fade(colorDark, 0.04), // 'rgba(0, 0, 0, 0.04)',
        },
        '&$hover:hover': {
          backgroundColor: fade(colorDark, 0.07), // 'rgba(0, 0, 0, 0.07)',
        },
      },
    },
    */
  },
  shadows: shadowsNew,
  typography: {
    useNextVariants: true,
  },
});

Object.keys(theme.palette.action).forEach((name) => {
  const item = theme.palette.action[name];
  if (typeof item === 'string') theme.palette.action[name] = item.replace('0, 0, 0', colorDarkRGB.replace(/[rgba()]/ig, ''));
});
theme.app = {
  colorBackground,
  colorDark,
  colorLine,
  fade,
  darken,
  colorBrand1: '#FFDC00', // '#d35400',
};

export default theme;
