import React from 'react';
import { formatFromUnix } from 'helpers/utils';
import ListSearch from 'components/ListSearch';
import BaseContainer from 'components/BaseContainer';
import { listFormaAsync, deleteFormaAsync, listForma } from 'reducers/forma';
import createListSearchForm from 'components/ListSearch/formForma';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Confirm from 'components/Confirm';
import { ROLE_ADMIN } from 'helpers/constants';
import binder from 'helpers/binder';

// import { connect } from 'react-redux';

const ListFilter = createListSearchForm({
  mainInput: {
    label: 'Buscar',
    name: 'filter',
    fullWidth: true,
    placeholder: 'Buscar formulario...',
    variant: 'outlined',
  },
  binder: {
    reduxForm: {
      form: 'formaListFilterForm',
      destroyOnUnmount: false,
    },
    states: {
      stateUser: ['user', 'login', 'data'],
      stateForm: ['form', 'formaListFilterForm', 'values'],
    },
  },
});

class FormList extends BaseContainer {
  ignoreStatus = true;

  handleFilter = (values) => {
    const tableList = document.getElementById('table-list');
    tableList.dispatchEvent(new Event('filtered'));
  }

  handleNewForm = () => {
    const { history, match } = this.props;
    history.push(`${match.url}/new/estandar`);
  }
  handleNewFormTop = () => {
  
    const { history, match } = this.props;
    history.push(`${match.url}/new/top`);
  }

  handleEdit = (item) => {
    const { history, match } = this.props;
    history.push(`${match.url}/${item.id}/${item.formFormat}`);
  }

  handleDelete = (event, row) => {
    const { dispatch } = this.props;
    event.stopPropagation();
    Confirm(`¿Esta seguro de eliminar el formulario "${row.original.name}"?`, { title: 'Confirmar' }).then(() => {
      deleteFormaAsync(row.original.id).then((result) => {
        let notification;
        if (result.success) {
          const tableList = document.getElementById('table-list');
          if (tableList) {
            tableList.dispatchEvent(new Event('refresh'));
          }
          notification = {
            message: 'Se ha eliminado el formulario',
            type: 'success',
          };
        } else {
          notification = {
            message: 'No se ha eliminado el formulario, vuelve a intentar en unos minutods',
            type: 'error',
          };
        }
        dispatch({
          type: 'APP/NOTIFICATIONS/ADD',
          notification,
        });
      });
    }, () => {});
  }

  toRenderTitlePage = () => 'Lista de formularios';

  toRender() {
    const { stateUser , stateForm } = this.props;
    const roleId = stateUser.getIn(['role', 'value']);
    const columns = [
      {
        accessor: 'name',
        Header: 'Nombre',
      },
      {
        accessor: 'station',
        Header: 'Medio',
      },
      {
        accessor: 'formFormat',
        Header: 'Tipo Formato',
      },
      {
        accessor: 'author',
        Header: 'Creado por',
      },
      {
        accessor: 'createAt',
        Header: 'Creado en',
      },
      {
        accessor: 'expireAt',
        Header: 'Vence en',
      },
    ];

    if (roleId === ROLE_ADMIN) {
      columns.push({
        accessor: 'actions',
        Header: '',
        Cell: row => (
          <IconButton
            aria-label="File"
            size="medium"
            color="secondary"
            className="btn-act"
            onClick={event => this.handleDelete(event, row)}
          >
            <DeleteIcon fontSize="inherit" />
          </IconButton>
        ),
      });
    }
    return (
      <React.Fragment>
        <ListSearch
          title="Formularios"
          handleNew={this.handleNewForm}
          handleNewTop={this.handleNewFormTop}
          handleEdit={this.handleEdit}
          handleListAsync={listFormaAsync}
          columns={columns}
          filtro = {stateForm}
          formatDataRow={row => ({
            id: row.id,
            name: row.name,
            station: row.station.label,
            formFormat:(row.formFormat?row.formFormat:'estandar'),
            author: row.author.label,
            createAt: formatFromUnix(row.createAt, 'dd/MM/yyyy HH:mm:ss'),
            expireAt: formatFromUnix(row.expireAt, 'dd/MM/yyyy HH:mm:ss'),
          })}
          filterContent={<ListFilter handleParentSubmit={this.handleFilter} />}
        />
      </React.Fragment>
    );
  }
}

// export default connect()(FormList);


export default binder({
  states: {
    stateUser: ['user', 'login', 'data'],
    stateForm: ['form', 'formaListFilterForm', 'values'],
  },
  actions: { listForma },
  component: FormList,
});
